import { Component, Input, OnInit } from '@angular/core';
import { AddressResultModel, T2AResultModel } from '../../../../../models/AddressResultModel';
import { AMLResultModel } from '../../../../../models/AMLResultModel';
import { AssociatedResultModel } from '../../../../../models/AssociatedResultModel';
import { CompaniesHouseResultModel } from '../../../../../models/CompaniesHouseResultModel';
import { CounterFraudResultModel, ProspectResultModel } from '../../../../../models/CounterFraudResultModel';
import { CriminalityResultModel } from '../../../../../models/CriminalityResultModel';
import { EmailageCheckResultModel, EmailCheckResultModel, IdCheckResultModel, RightToWorkCheckResultModel } from '../../../../../models/EmailResultModel';
import { CCJResultModel, FinancialResultModel, InsolvencyResultModel } from '../../../../../models/FinancialResultModel';
import { LandRegistryResultModel } from '../../../../../models/LandRegistryResultModel';
import { MobileResultModel } from '../../../../../models/MobileResultModel';
import { PIPLResultModel } from '../../../../../models/PIPLResultModel';
import { RequestData } from '../../../../../models/SearchRequestModel';
import { SocialMediaResultModel } from '../../../../../models/SocialMediaResultModel';
import { SportsResultModel } from '../../../../../models/SportsResultModel';

@Component({
  selector: 'app-export-pdf-person',
  templateUrl: './export-pdf-person.component.html',
  styleUrls: ['./export-pdf-person.component.css', '../../../../shared/shared.searchpage.css']
})
export class ExportPdfPersonComponent implements OnInit {

  @Input() public requestData: RequestData = new RequestData();
  @Input() public address: AddressResultModel = new AddressResultModel();
  @Input() public aml: AMLResultModel = new AMLResultModel();
  @Input() public ccj: CCJResultModel = new CCJResultModel();
  @Input() public insolvency: InsolvencyResultModel = new InsolvencyResultModel();
  @Input() public mobile: MobileResultModel = new MobileResultModel();
  @Input() public counterFraud: CounterFraudResultModel = new CounterFraudResultModel();
  @Input() public email: EmailCheckResultModel = new EmailCheckResultModel();
  @Input() public emailage: EmailageCheckResultModel = new EmailageCheckResultModel();
  @Input() public rightToWork: RightToWorkCheckResultModel = new RightToWorkCheckResultModel();
  @Input() public idCheck: IdCheckResultModel = new IdCheckResultModel();
  @Input() public financial: FinancialResultModel = new FinancialResultModel();
  @Input() public companiesHouse: CompaniesHouseResultModel = new CompaniesHouseResultModel();
  @Input() public prospect: ProspectResultModel = new ProspectResultModel();
  @Input() public associated: AssociatedResultModel = new AssociatedResultModel();
  @Input() public criminality: CriminalityResultModel = new CriminalityResultModel();
  @Input() public sports: SportsResultModel = new SportsResultModel();
  @Input() public pipl: PIPLResultModel = new PIPLResultModel();
  @Input() public opensource: MobileResultModel = new MobileResultModel();
  @Input() public emailValidation: EmailCheckResultModel = new EmailCheckResultModel()
  @Input() public socialMedia: SocialMediaResultModel = new SocialMediaResultModel();
  @Input() public t2a: T2AResultModel = new T2AResultModel();
  @Input() public landRegistry: LandRegistryResultModel = new LandRegistryResultModel();

  constructor() { }

  ngOnInit(): void {
  }

}
