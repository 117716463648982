import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScreenInputData } from '../../../screensearch/Models/CSVRecords';
import { TenantSettings } from '../../../subscription/Models/UserSettings';
import { AVAResultData } from '../search-pages/models/AVAResultModel';
import { Item, Items, SearchDomain, Token } from '../search-pages/models/Items';
import { RTWDepartmentDropdownModel } from '../search-pages/models/RTWModel';
import { RequestData } from '../search-pages/models/SearchRequestModel';
import { TenantRegion } from '../search-pages/models/TenantRegionResposne';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) { }

  public GetPersonSearch(requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/GetPersonSearch', requestData);
  }

  public SearchAML(requestData: RequestData): Observable<any> {
    return this.http.post<any>('/api/V3Search/SearchAMLData', requestData);
  }

  public SearchAVA(requestData: RequestData): Observable<AVAResultData> {
    return this.http.post<AVAResultData>('/api/V3Search/SearchAVA', requestData);
  }

  public GetMobileData(requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/GetMobileData', requestData);
  }

  public EmailDomainCheck(requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/EmailDomainCheck', requestData);
  }

  public EmailValidationCheck(requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/EmailValidation', requestData);
  }

  public GetCounterFraudData(requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/GetCounterFraudData', requestData);
  }

  public DirectorshipSearchData(requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/DirectorshipSearch', requestData);
  }

  public GetCompaniesHouse(requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/GetCompaniesHouse', requestData);
  }

  public GetProspectHitData(requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/GetProspectHitData', requestData);
  }

  public GetProspectProfileData(requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/GetProspectProfileData', requestData);
  }

  public RightToWorkSearch(requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/RightToWork', requestData);
  }

  public IDCheck(requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/IDCheck', requestData);
  }

  public EmailAge(requestData: RequestData, debounceReason: string | null): Observable<object> {
    return this.http.post<object>(`/api/V3Search/EmailAge?debounceReason=${debounceReason}`, requestData);
  }

  public SearchCCJAndInsolvency(requestData: RequestData, finalcialData: any): Observable<any> {
    const request = {
      "finalcialData": finalcialData,
      "requestData": requestData
    }
    return this.http.post<any>('/api/V3Search/SearchCCJInsolvencyDetailData', request);
  }

  public CheckProsectHitAndProfileAccessClient(): Observable<Object> {
    return this.http.get<Object>('/api/V3Search/CheckProspectClientData');
  }

  public getDomainJson(): Observable<SearchDomain[]> {
    return this.http.get<SearchDomain[]>('../assets/jsonfiles/searchDomains.json');
  }

  public AssociatedSearchData(searchDomain: string, requestData: RequestData): Observable<Items[]> {
    return this.http.post<Items[]>('/api/V3Search/AssociatedDataSearch?&searchDomain=' + searchDomain, requestData.profileData);
  }

  public BusinessOpenSourceSearch(searchDomain: string, requestData: RequestData): Observable<Items[]> {
    return this.http.post<Items[]>('/api/V3Search/BusinessOpenSourceSearch?&searchDomain=' + searchDomain, requestData);
  }

  public refreshtoken(token: Token): Observable<Token> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<Token>('/api/Token/Refresh', token);
  }

  public SearchData(lexicon: string, searchDomain: string, requestData: RequestData): Observable<object> {
    return this.http.post<object>('/api/V3Search/DataSearch?lexiconKey=' + lexicon + '&searchDomain=' + searchDomain, requestData);
  }

  public SocialMedia(EachsocialMediaCategory: string, searchDomain: string, requestData: RequestData): Observable<object> {
    return this.http.post<Items[]>('/api/V3Search/SearchSocialMediaData?EachsocialMediaCategory=' + EachsocialMediaCategory + '&searchDomain=' + searchDomain, requestData);
  }

  public analyseResultRanking(searchUniqueIdlist: string[], lexiconCategory: string, searchDomain: string, devData: boolean, requestData: RequestData): Observable<Items[]> {
    const allRequestData = {
      "searchUniqueIdlist": searchUniqueIdlist,
      "requestData": requestData
    }
    return this.http.post<Items[]>('/api/V3Search/AnalyseResultRanking?category=' + lexiconCategory + '&searchDomain=' + searchDomain + '&devData=' + devData, allRequestData);
  }

  public updateAudit(searchType: string, auditKey: string, api: number, data: string[]) {
    return this.http.post('/api/V3Search/UpdateAuditByAPI?auditKey=' + auditKey + '&api=' + api + '&searchType=' + searchType, data);
  }

  public GetCriminalityRankForSearch(): Observable<TenantSettings> {
    return this.http.get<TenantSettings>('api/Subscription/GetCriminalitySearchRankForSearch');
  }

  public SearchSports(EachlifestyleCategory: string, searchDomain: string, requestData: RequestData): Observable<Items[]> {
    return this.http.post<Items[]>('/api/V3Search/SearchLifeStyleData?EachlifestyleCategory=' + EachlifestyleCategory + '&searchDomain=' + searchDomain, requestData.profileData);
  }

  public SearchPIPL(requestData: RequestData): Observable<any> {
    return this.http.post<any>('/api/V3Search/PIPLSearch', requestData);
  }

  public AllowedLicence(): Observable<Item[]> {
    return this.http.get<Item[]>('api/V3Search/GetLicenceItem');
  }

  public GetAllowedSearchAvailablityItems(): Observable<Item[]> {
    return this.http.get<Item[]>('api/V3Search/GetAllowedSearchAvailablityItems');
  }

  public GetAuditKey(): Promise<any> {
    return this.http.get('api/V3Search/GetAuditKey').toPromise();
  }

  public GetScreenSearchData(): Observable<any> {
    return this.http.get('api/screensearch');
  }

  public ProcessScreenSearch(screenInputData: ScreenInputData): Observable<any> {
    return this.http.post('api/screensearch', screenInputData);
  }

  public MobileSearchScreen(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/MobileSearchScreen', requestData);
  }

  public OpenSourceMobileSearch(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/MobileOpenSource', requestData);
  }

  public OpenSourceEmailSearch(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/EmailOpenSource', requestData);
  }

  public OpenSourceSearch(requestData: RequestData, domain: string): Observable<any> {
    return this.http.post<any>('api/V3Search/OpenSourceSearch?searchDomain=' + domain, requestData);
  }

  public DVLAMotor(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/DVLAMotor', requestData);
  }

  public GetEmailageOrdId(): Observable<any> {
    return this.http.get<any>('api/V3Search/GetEmailageOrdId');
  }

  public ExperianMotor(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/ExperianMotor', requestData);
  }

  public DocumentValidation(reference: string, searchType: string, formData: FormData): Observable<object> {
    return this.http.post<object>(`/api/V3Search/DocumentValidation?reference=${reference}&searchType=${searchType}`, formData);
  }

  public VatSearch(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/VATSearch', requestData);
  }

  public BusinessSearch(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/BusinessSearch', requestData);
  }

  public MotHistory(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/MotHistory', requestData);
  }

  public GetT2A(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/GetT2AAPIResult', requestData);
  }

  public getUserDetails(): Observable<any> {
    return this.http.get<any>('api/V3Search/GetUserDetails');
  }

  public GetRTWDepartmentDropdown(): Observable<RTWDepartmentDropdownModel[]> {
    return this.http.get<any>('api/V3Search/GetRTWDepartmentDropdown');
  }

  public GetLandRegistryResult(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/LandRegistryResult', requestData);
  }

  public MobileLexisNexisReverseSearch(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/MobileLexisNexisReverseSearch', requestData);
  }

  public GetTenantRegion(): Observable<TenantRegion> {
    return this.http.get<any>('api/Subscription/GetTenantRegion');
  }

  public GetStatesForDropdown(): Observable<any> {
    return this.http.get<any>('api/V3Search/GetStatesForDropdown');
  }

  public LandRegistryBusinessResult(requestData: RequestData): Observable<any> {
    return this.http.post<any>('api/V3Search/LandRegistryBusinessResult', requestData);
  }

}
