<section id="motor-search" class="search">
  <div class="container">
    <div class="logo-div d-flex justify-content-center">
      <img class="logo" src="../../../../../assets/Images/new/HUT-icon.v3-motor-RO.png" />
    </div>
    <form role="form" [formGroup]="motorForm">
      <div class="form-group row form-top-margin">
        <div class="col-md-8 form-data-background form-borderdesign  shadow-lg p-3">
          <div class="row padding-form-design">
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Reference:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                      <p class="text-danger" *ngIf="motorForm.get('reference').errors?.required">
                        <strong><small>Reference is required</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>VRN:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="vrn" autocomplete="off" />
                      <p class="text-danger" *ngIf="motorForm.get('vrn').errors?.required">
                        <strong><small>VRN is required</small></strong>
                      </p>
                      <p class="text-danger" *ngIf="motorForm.get('vrn').errors?.pattern">
                        <strong><small>VRN is not valid.</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 padding-form-design pb-2">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-12 offset-md-3">
                    <div class="form-group">
                      <button type="submit" class="btn colorRed mx-2" (click)="motorSearch()">Search <i class="fa fa-search" aria-hidden="true"></i></button>
                      <button type="button" class="btn colorRed btn-pdf mx-2"
                              *ngIf="saveAsPDFButtonVisibility"
                              (click)="generatePDF()"
                              [disabled]="avaResultModel?.isLoading || dvlaResultModel?.isLoading || prospectResultModel?.isProspectHitLoading || experianResultModel?.isLoading || motHistoryResultModel?.isLoading">
                        Save as PDF
                      </button>
                      <button class="btn colorRed reset-btn mx-2"
                              *ngIf="clearSearchButtonVisibility"
                              (click)="motorClearSearch()"
                              [disabled]="avaResultModel?.isLoading || dvlaResultModel?.isLoading || prospectResultModel?.isProspectHitLoading || experianResultModel?.isLoading || motHistoryResultModel?.isLoading">
                        Clear Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 toggle-div form-borderdesign result-form-background shadow-lg p-3 #F7F7F7">
          <ul class="ul-toggle">
            <li class="result-animation" [ngClass]="motorForm.get('vrn').value ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" formControlName="avaAccess">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/AVA.svg" class="icon-size" />
              <span>AVA</span>
            </li>
          </ul>
        </div>
      </div>
    </form>

    <div class="container mb-5" *ngIf="showResultTab">
      <div class="mb-5">
        <div class="background-white result-display-padding form-borderdesign result-view-align" style="border: 1px solid rgba(0,0,0,.125);">
          <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
            <li class="nav-item" *ngIf="isExperianTabShow">
              <a class="nav-link" id="address-search-tab" data-toggle="tab" href="#hutmotorSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>HUT Motor</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" id="address-search-tab" data-toggle="tab" href="#motorSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>DVLA Realtime</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="aml-search-tab" data-toggle="tab" href="#avaSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>AVA</b></a>
            </li>
            <li class="nav-item" *ngIf="prospectResultModel.isProspectHitAccess">
              <a class="nav-link" id="prospect-search-tab" data-toggle="tab" href="#prospectSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Prospect</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="mothistory-search-tab" data-toggle="tab" href="#motHistorySearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>MOT History</b></a>
            </li>
          </ul>

          <div class="tab-content" id="searchResultTabContent">
            <!---------------------- Category Search table for Main results --------------->
            <!-- HUT Motor Results -->
            <div *ngIf="isExperianTabShow" class="tab-pane fade show tab-height" id="hutmotorSearch" role="tabpanel" aria-labelledby="address-search-tab">
              <app-hutmotor-result-motor [experian]="experianResultModel"></app-hutmotor-result-motor>
            </div>

            <!-- Vehicle Data Results -->
            <div class="tab-pane fade show tab-height active" id="motorSearch" role="tabpanel" aria-labelledby="address-search-tab">
              <app-motor-result-motor [motor]="dvlaResultModel"></app-motor-result-motor>
            </div>

            <!-- AVA Result -->
            <div class="tab-pane fade show tab-height" id="avaSearch" role="tabpanel" aria-labelledby="aml-search-tab">
              <app-ava-result-motor [ava]="avaResultModel"></app-ava-result-motor>
            </div>

            <!-- Prospect Result -->
            <div class="tab-pane fade show tab-height" id="prospectSearch" role="tabpanel" aria-labelledby="prospect-search-tab">
              <app-prospect-result-personal [prospect]="prospectResultModel" (prospectProfileRequestEventEmitter)="prospectProfileRequest()"></app-prospect-result-personal>
            </div>

            <!-- MOT History Result -->
            <div class="tab-pane fade show tab-height" id="motHistorySearch" role="tabpanel" aria-labelledby="mothistory-search-tab">
              <app-mothistory-result-motor [motHistory]="motHistoryResultModel"></app-mothistory-result-motor>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-1"></div>
  </div>
</section>
<p-confirmDialog key="ProspectProfileRequest" [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<div style="position: absolute; top: 0; right: 120%;">
  <kendo-pdf-export #pdf>
    <app-export-pdf-motor [requestData]="requestDataModel"
                          [ava]="avaResultModel"
                          [dvla]="dvlaResultModel"
                          [prospect]="prospectResultModel"
                          [experian]="experianResultModel"
                          [motHistory]="motHistoryResultModel"
                          [isExperianTabShow]="isExperianTabShow"></app-export-pdf-motor>
  </kendo-pdf-export>
</div>
