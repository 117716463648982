import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { PageMargin } from '@progress/kendo-drawing/dist/npm/pdf';
import { isPossiblePhoneNumber, parsePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { ConfirmationService } from 'primeng/api';
import { MessagingService } from '../../../../services/messaging.service';
import { SearchService } from '../../../../services/search.service';
import { Shared } from '../../../../services/shared';
import { AddressResultModel, T2AResultModel } from '../../../models/AddressResultModel';
import { AMLResultModel } from '../../../models/AMLResultModel';
import { AssociatedResultModel } from '../../../models/AssociatedResultModel';
import { CompaniesHouseResultModel } from '../../../models/CompaniesHouseResultModel';
import { CounterFraudResultModel, ProspectResultModel } from '../../../models/CounterFraudResultModel';
import { CriminalityResultModel } from '../../../models/CriminalityResultModel';
import { EmailageCheckResultModel, EmailCheckResultModel, IdCheckResultModel, RightToWorkCheckResultModel } from '../../../models/EmailResultModel';
import { CCJResultModel, FinancialResultModel, InsolvencyResultModel } from '../../../models/FinancialResultModel';
import { Item, Items, SearchDomain, Token } from '../../../models/Items';
import { LandRegistryResultModel } from '../../../models/LandRegistryResultModel';
import { MobileResultModel, OpenSourceResultModel } from '../../../models/MobileResultModel';
import { PIPLResultModel } from '../../../models/PIPLResultModel';
import { AccessData, ProfileData, RequestData } from '../../../models/SearchRequestModel';
import { SearchType } from '../../../models/SearchType';
import { SocialMediaResultModel } from '../../../models/SocialMediaResultModel';
import { SportsResultModel } from '../../../models/SportsResultModel';
import { EmailvalidationResultEmailComponent } from '../../email/result-pages/emailvalidation-result-email/emailvalidation-result-email.component';
declare var $: any;

@Component({
  selector: 'app-uk-personal',
  templateUrl: './uk-personal.component.html',
  styleUrls: ['./uk-personal.component.css', '../../shared/shared.searchpage.css']
})
export class UkPersonalComponent implements OnInit {
  @ViewChild('pdf') pdf: PDFExportComponent;
  @Output() disableExitEventEmitter = new EventEmitter();

  public postcodeRegex = /^(([gG][iI][rR] {0,}0[aA]{2})|(([aA][sS][cC][nN]|[sS][tT][hH][lL]|[tT][dD][cC][uU]|[bB][bB][nN][dD]|[bB][iI][qQ][qQ]|[fF][iI][qQ][qQ]|[pP][cC][rR][nN]|[sS][iI][qQ][qQ]|[iT][kK][cC][aA]) {0,}1[zZ]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yxA-HK-XY]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
  public personalForm: FormGroup;
  public validationType: any = {
    'reference': [Validators.required],
    'name': [Validators.required],
    'email': [Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')],
    'mobile': [Validators.pattern('^[0-9]{11}$')],
    'postcode': [Validators.pattern(this.postcodeRegex)]
  };
  public accessData: AccessData = new AccessData();
  public showResultTab: boolean = false;
  public prospectProfileButtonText_RequestProfile = "Request Profile";
  public prospectProfileButtonText_Requested = "Requested";
  public searchDomains: SearchDomain[] = [];
  public activeSearchDomains: SearchDomain[] = [];
  public refreshtoken = new Token(null);
  public emailageOrgId: string;
  public saveAsPDFButtonVisibility: boolean = true;
  public clearSearchButtonVisibility: boolean = true;
  public loggedInUsername: string = "";

  // PDF
  public requestDataModel: RequestData = new RequestData();

  // Result Models
  public addressResultModel: AddressResultModel = new AddressResultModel();
  public amlResultModel: AMLResultModel = new AMLResultModel();
  public ccjResultModel: CCJResultModel = new CCJResultModel();
  public insolvencyResultModel: InsolvencyResultModel = new InsolvencyResultModel();
  public mobileResultModel: MobileResultModel = new MobileResultModel();
  public counterFraudResultModel: CounterFraudResultModel = new CounterFraudResultModel();
  public emailResultModel: EmailCheckResultModel = new EmailCheckResultModel();
  public emailageResultModel: EmailageCheckResultModel = new EmailageCheckResultModel();
  public rightToWorkResultModel: RightToWorkCheckResultModel = new RightToWorkCheckResultModel();
  public idCheckResultModel: IdCheckResultModel = new IdCheckResultModel();
  public financialResultModel: FinancialResultModel = new FinancialResultModel();
  public companiesHouseResultModel: CompaniesHouseResultModel = new CompaniesHouseResultModel();
  public prospectResultModel: ProspectResultModel = new ProspectResultModel();
  public associatedResultModel: AssociatedResultModel = new AssociatedResultModel();
  public criminalityResultModel: CriminalityResultModel = new CriminalityResultModel();
  public sportsResultModel: SportsResultModel = new SportsResultModel();
  public piplResultModel: PIPLResultModel = new PIPLResultModel();
  public opensourceResult: OpenSourceResultModel = new OpenSourceResultModel();
  public emailValidationResultModel: EmailCheckResultModel = new EmailCheckResultModel()
  public socialMediaResultModel: SocialMediaResultModel = new SocialMediaResultModel();
  public t2aResultModel: T2AResultModel = new T2AResultModel();
  public landRegistryResultModel: LandRegistryResultModel = new LandRegistryResultModel();

  constructor(
    private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.searchService.GetAllowedSearchAvailablityItems().subscribe(data => {
      let keys = Array.from(data).map(x => x.key);
      if (!keys.find(x => x == "PERSONAL")) {
        this.router.navigate(['/v3']);
      }
    }, error => {
      console.log(error);
    })

    this.personalForm = this.formBuilder.group({
      profile: this.formBuilder.group({
        audit: [""],
        reference: [""],
        name: [""],
        postcode: [""],
        mobile: [""],
        email: [""],
        dob: [""],
        address: [""],
        associations: this.formBuilder.array([])
      }),
      access: this.formBuilder.group({
        landregistry: [false],
        financial: [false],
        quick: [false]
      })
    });
    for (var i = 1; i <= 4; i++) {
      this.associationEntities.push(this.newAssociationEntities());
    }
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
    this.CheckProspectHitAndProfileAccess();
    this.GetSearchDomainJson();
    // this.getCriminalityRelevantRank();
    this.getLicenceItems();
    this.getEmailageOrgId();
    this.getUserDetails();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  public disableExit() {
    if (this.saveAsPDFButtonVisibility == false) {
      this.disableExitEventEmitter.emit(false);
      return false;
    }

    if (this.criminalityResultModel?.isLoading || this.addressResultModel?.isLoading ||
      this.financialResultModel?.isLoading || this.amlResultModel?.isLoading || this.emailageResultModel?.isLoading ||
      this.counterFraudResultModel?.isLoading || this.mobileResultModel?.isLoading || this.associatedResultModel?.isLoading ||
      this.ccjResultModel?.isLoading || this.companiesHouseResultModel?.isLoading || this.emailValidationResultModel?.isLoading ||
      this.insolvencyResultModel?.isLoading || this.opensourceResult?.isLoading ||
      this.piplResultModel?.isLoading || this.socialMediaResultModel?.isLoading || this.sportsResultModel?.isLoading ||
      this.t2aResultModel?.isLoading || this.prospectResultModel?.isProspectHitLoading || this.landRegistryResultModel?.isLoading) {
        this.disableExitEventEmitter.emit(true);
        return true;
    }

    this.disableExitEventEmitter.emit(false);
    return false;
  }

  // Search button click event
  public personalSearch(): void {
    this.addValidators();
    this.personalForm.updateValueAndValidity();

    if (this.personalForm.valid) {

      Promise.resolve(this.getRandomString(10)).then(
        (data) => {

          this.saveAsPDFButtonVisibility = true;
          this.clearSearchButtonVisibility = true;

          this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();

          this.setActiveResultTab();

          // AML Check
          this.amlCheck(requestObj);

          // Telisign Check
          this.telisignCheck(requestObj);

          // Email Check
          this.emailCheck(requestObj);

          // Email Validation
          this.emailValidationCheck(requestObj);

          // Load Emailage
          this.loadEmailAgeCheck(requestObj);

          //Counter Fraud Check
          this.counterFraudCheck(requestObj);

          // Financial Check
          this.financialCheck(requestObj);

          // T2A Search
          this.t2aSearch(requestObj);

          // Companies House Check
          this.companiesHouse(requestObj);

          if (this.prospectResultModel.isProspectHitAccess) {
           // Prospect Hit Check
           this.prospectHitCheck(requestObj);
          }

          // Land Registry
          this.landRegistry(requestObj);

          // PIPL API
          this.GetPIPLData(requestObj);

          // Social media Check
          this.socialMediaSearch(requestObj);

          // Criminality Check
          this.criminalityCheck(requestObj);

          // Associated Check
          this.associatedCheck();

          // Sports Check
          this.sportsCheck(requestObj);

          // Opensource search
          this.openSourceSearch(requestObj);

        }
      );
    }
  }

  public landRegistry(requestObj: RequestData): void {
    this.landRegistryResultModel.isLoading = false;
    this.landRegistryResultModel.noSearchSelect = false;
    this.landRegistryResultModel.responseData = null;
    this.landRegistryResultModel.searchFail = false;
    this.landRegistryResultModel.validation = false;
    this.landRegistryResultModel.noRelevantData = false;

    if (requestObj.accessData.isLandRegistryAccess) {
      if (requestObj.profileData.fullName && requestObj.profileData.postalCode && requestObj.profileData.addressLine1) {
        this.landRegistryResultModel.isLoading = true;
        this.searchService.GetLandRegistryResult(requestObj).subscribe(data => {
          var json = JSON.parse(JSON.stringify(data));
          this.landRegistryResultModel.isLoading = false;
          if (json.status == 200) {
            json.data.completeResponse = JSON.parse(json.data.completeResponse);
            if (json.data.prsDetails.matches.length == 0 && !json.data.prsDetails.matchResult) {
              this.landRegistryResultModel.noRelevantData = true;
            } else {
              this.landRegistryResultModel.responseData = json.data;
            }
          } else {
            this.landRegistryResultModel.searchFail = true
          }
        }, error => {
          console.log(error);
          this.landRegistryResultModel.isLoading = false;
          this.landRegistryResultModel.searchFail = true;
        });
      } else {
        this.landRegistryResultModel.validation = true;
      }
    } else {
      this.landRegistryResultModel.noSearchSelect = true;
    }
  }

  public setActiveResultTab() {
    setTimeout(() => {
      // Tab Header
      var navLine = $('.nav-link');
      $.each(navLine, function (index, x) {
        if ($(x).hasClass("active")) {
          $(x).removeClass("active")
        }
      })

      // Tab Result
      var tabPane = $('.tab-pane');
      $.each(tabPane, function (index, x) {
        if ($(x).hasClass("active")) {
          $(x).removeClass("active")
        }
      })

      // Show first tab as active
      $('#address-search-tab').addClass("active");
      $('#addressSearch').addClass("active");
    }, 2000);
  }

  public getUserDetails() {
    this.searchService.getUserDetails().subscribe(data => {
      this.loggedInUsername = data.username;
    }, error => {
      console.log(error);
    })
  }

  public generatePDF() {
    var requestObj: RequestData = this.prepareRequestData();
    this.requestDataModel = requestObj;
    if (this.requestDataModel.profileData.associatedNames.length < 4) {
      let associationLength = this.requestDataModel.profileData.associatedNames.length;
      for (let i = associationLength; i < 4; i++) {
        this.requestDataModel.profileData.associatedNames.push({ name: "" });
      }
    }
    if (this.requestDataModel.profileData.phoneNumber) {
      this.requestDataModel.profileData.phoneNumber = this.personalForm.get('profile.mobile').value;
    }

    setTimeout(() => {
      this.pdf.forcePageBreak = ".page-break"
      this.pdf.keepTogether = ".keep-together";
      this.pdf.paperSize = "A4";
      const margin = {
        top: '2cm',
        bottom: '3cm',
        left: '1cm',
        right: '1cm'
      }
      this.pdf.margin = margin;
      this.pdf.scale = 0.5;
      this.pdf.saveAs('export.pdf');
    }, 2000);

  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.personalForm.patchValue({
          profile: {
            audit: data
          }
        });
      });
  }

  // To get associated entities as formarray for component
  get associationEntities(): FormArray {
    return <FormArray>this.personalForm.get('profile.associations');
  }

  // To add associated entities
  newAssociationEntities(): FormGroup {
    return this.formBuilder.group({
      name: ""
    });
  }

  public disableAccessData(): void {
    this.personalForm.patchValue({
      access: {
        landregistry: this.accessData.isLandRegistryAccess,
        financial: this.accessData.isFinancialAccess,
        quick: !this.accessData.isCriminalityAccess
      }
    });

    if (!this.accessData.isFinancialToggle)
      this.personalForm.get("access.financial").disable();

    if (!this.accessData.isCriminalityToggle)
      this.personalForm.get("access.quick").disable();

    if (!this.accessData.isLandRegistryToggle)
      this.personalForm.get("access.landregistry").disable();
  }

  public getLicenceItems(): void {
    this.searchService.AllowedLicence().subscribe(data => {
      this.getSecondAccess(data);
      this.disableAccessData();
    });
  }

  public getSecondAccess(Items: Item[]) {
    Items.forEach(item => {
      if (item.licenceType.toLowerCase() == "search") {
        switch ((item.key)) {
          case "HUTDB": {
            this.accessData.isHUTDatabaseAccess = item.defaultValue;
            this.accessData.isHUTDatabaseToggle = item.toggle;
            break;
          }
          case "MOBILE": {
            this.accessData.isMobileAccess = item.defaultValue;
            this.accessData.isMobileToggle = item.toggle;
            break;
          }
          case "EMAIL": {
            this.accessData.isEmailAccess = item.defaultValue;
            this.accessData.isEmailToggle = item.toggle;
            break;
          }
          case "IDENTITY": {
            this.accessData.isIdentityAccess = item.defaultValue;
            this.accessData.isIdentityToggle = item.toggle;
            break;
          }
          case "FINANCIAL": {
            this.accessData.isFinancialAccess = item.defaultValue;
            this.accessData.isFinancialToggle = item.toggle;
            break;
          }
          case "ADDRESS": {
            this.accessData.isAddressAccess = item.defaultValue;
            this.accessData.isAddressToggle = item.toggle;
            break;
          }
          case "CRIMINALITY": {
            this.accessData.isCriminalityAccess = item.defaultValue;
            this.accessData.isCriminalityToggle = item.toggle;
            break;
          }
          case "SOCIAL": {
            this.accessData.isSocialMediaAccess = item.defaultValue;
            this.accessData.isSocialMediaToggle = item.toggle;
            break;
          }
          case "COUNTER_FRAUD": {
            this.accessData.isCounterFraudAccess = item.defaultValue;
            this.accessData.isCounterFraudToggle = item.toggle;
            break;
          }
          case "SPORTS": {
            this.accessData.isSportsAccess = item.defaultValue;
            this.accessData.isSportsToggle = item.toggle;
            break;
          }
          case "ADVERT": {
            this.accessData.isAdvertsAccess = item.defaultValue;
            this.accessData.isAdvertsToggle = item.toggle;
            break;
          }
          case "COMPANY_HOUSE": {
            this.accessData.isCompaniesHouseAccess = item.defaultValue;
            this.accessData.isCompaniesHouseToggle = item.toggle;
            break;
          }
          case "AVA": {
            this.accessData.isAVAAccess = item.defaultValue;
            this.accessData.isAVAToggle = item.toggle;
            break;
          }
          case "AML": {
            this.accessData.isAMLAccess = item.defaultValue;
            this.accessData.isAMLToggle = item.toggle;
            break;
          }
          case "CCJ_Detail": {
            this.accessData.isCCJDetailAccess = item.defaultValue;
            this.accessData.isCCJDetailToggle = item.toggle;
            break;
          }
          case "Insolvency_Detail": {
            this.accessData.isInsolvencyDetailAccess = item.defaultValue;
            this.accessData.isInsolvencyDetailToggle = item.toggle;
            break;
          }
          case "RTW": {
            this.accessData.isRTWAccess = item.defaultValue;
            this.accessData.isRTWToggle = item.toggle;
            break;
          }
          case "IDCHECK": {
            this.accessData.isIDCheckAccess = item.defaultValue;
            this.accessData.isIDCheckToggle = item.toggle;
            break;
          }
          case "LANDREGISTRY": {
            this.accessData.isLandRegistryAccess = item.defaultValue;
            this.accessData.isLandRegistryToggle = item.toggle;
            break;
          }
        }
      }
    });
  }


  public t2aSearch(requestObj: RequestData): void {
    this.t2aResultModel.isLoading = false;
    this.t2aResultModel.noSearchSelect = false;
    this.t2aResultModel.responseData = null;
    this.t2aResultModel.searchFail = false;
    this.t2aResultModel.validation = false;

    if (requestObj.profileData.fullName && requestObj.profileData.postalCode && requestObj.profileData.addressLine1) {
      this.t2aResultModel.isLoading = true;
      this.searchService.GetT2A(requestObj).subscribe(data => {
        this.t2aResultModel.isLoading = false;
        let responseData = data.data;
        if (responseData) {
          this.t2aResultModel.responseData = JSON.parse(responseData);
        } else {
          this.t2aResultModel.responseData = null;
        }
      }, error => {
        console.log(error);
        this.t2aResultModel.isLoading = false;
        this.t2aResultModel.searchFail = true;
      });
    } else {
      this.t2aResultModel.validation = true;
    }
  }

  public openSourceSearch(requestObj: RequestData): void {
    this.opensourceResult.isLoading = false;
    this.opensourceResult.noSearchSelect = false;
    this.opensourceResult.responseData = [];
    this.opensourceResult.tempResponseData = [];
    this.opensourceResult.searchFail = false;
    this.opensourceResult.validationError = false;
    this.opensourceResult.openSourceResultCount = 0;

    this.opensourceResult.isLoading = true;

    this.activeSearchDomains.forEach((searchDomain: SearchDomain) => {
      let index: number = 0;
      this.eachOpenSourceSearch(searchDomain, requestObj, index);
    });
  }

  public eachOpenSourceSearch(domain: SearchDomain, requestObj: RequestData, index: number) {
    this.searchService.OpenSourceSearch(requestObj, domain.UniqueCode).subscribe((data) => {
      this.opensourceResult.searchFail = false;

      if (data["newsitems"] != null) {
        this.opensourceResult.tempResponseData = this.opensourceResult.tempResponseData.concat(data["newsitems"]);
      }
      this.opensourceResult.openSourceResultCount++;

      if (this.opensourceResult.openSourceResultCount == this.activeSearchDomains.length) {
        this.opensourceResult.isLoading = false;
        if (this.opensourceResult.tempResponseData.length > 0) {
          this.opensourceResult.responseData = this.getUniqueResults(this.opensourceResult.tempResponseData, 'link');
        }
        //this.opensourceResult.responseData = this.opensourceResult.responseData;
      }
    }, (error) => {
      console.log(error);
      if (error.status == 401 && error.headers.has('Token-Expired')) {
        this.refreshtoken.token = localStorage.getItem("access_token");
        this.searchService.refreshtoken(this.refreshtoken)
          .subscribe(
            data => {
              localStorage.setItem("access_token", data["access_token"]);
              localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
              localStorage.setItem("token_type", data["token_type"]);
              this.associatedCheck();
            },
            error => {
              if (error.status == 400) {
                this.logout();
              }
            });
      }
      else if (error.status == 502 || error.status == 504) {
        index++;
        if (index < 2) {
          this.eachOpenSourceSearch(domain, requestObj, index);
        }
        else {
          this.opensourceResult.isLoading = false;
          this.opensourceResult.searchFail = true;
        }
      }
      else {
        this.opensourceResult.isLoading = false;
        this.opensourceResult.searchFail = true;
      }
    });
  }

  // Social media (PIPL) API call
  public GetPIPLData(requestObj: RequestData): void {
    this.piplResultModel.isLoading = false;
    this.piplResultModel.noRelevantData = false;
    this.piplResultModel.searchFail = false;
    this.piplResultModel.responseData = null;
    this.piplResultModel.noSearchSelect = false;
    this.piplResultModel.validation = false;

    if (requestObj.accessData.isSocialMediaAccess) {
      if (requestObj.profileData.fullName && (requestObj.profileData.email || requestObj.profileData.phoneNumber)) {
        this.piplResultModel.isLoading = true;
        this.searchService.SearchPIPL(requestObj).subscribe((data) => {
          this.piplResultModel.isLoading = false;
          let response = data.data;
          if (response) {
            this.piplResultModel.responseData = response;
          }
          else {
            this.piplResultModel.noRelevantData = true;
          }
        }, (error) => {
          this.piplResultModel.searchFail = true;
          this.piplResultModel.isLoading = false;
          console.log(error);
        })
      } else {
        this.piplResultModel.validation = true;
      }
    } else {
      this.piplResultModel.noSearchSelect = true;
    }

  }

  // Getting search domain json for scraping
  public GetSearchDomainJson() {
    this.searchService.getDomainJson()
      .subscribe((data) => {
        this.searchDomains = data;
        this.activeSearchDomains = data.filter(x => x.IsActive == true);
      }, error => {
        console.log(error);
      });
  }

  // Sports API call
  public sportsCheck(requestObj: RequestData): void {
    this.sportsResultModel.isLoading = false;
    this.sportsResultModel.noSearchSelect = false;

    if (requestObj.accessData.isSportsAccess) {
      this.sportsResultModel.isLoading = true;
      this.sportsResultModel.collapseSportsClass = "show";
      this.sportsResultModel.collapseSportsStatus = "Hide Status";
      this.sportsResultModel.collapseSportsIcon = "fa-chevron-up";
      this.sportsResultModel.sportsComplete = false;

      this.sportsResultModel.sportsStatus =
        [
          { name: "Running", displayname: "Running/Cycling", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Water", displayname: "Water based activities", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Ball", displayname: "Ball activities", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Racket", displayname: "Racket Sports", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Hockey", displayname: "Hockey & Ice Hockey", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Rugby", displayname: "Rugby Sports", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "Boxing", displayname: "Kick Boxing/Judo & Boxing", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "MMA", displayname: "MMA Sports", status: "Searching . . .", searchloading: true, statusCount: 0 },
        ];

      this.sportsResultModel.sportsCategory.forEach((item: string) => {
        let sportsIndex: number = 0;
        this.activeSearchDomains.forEach((searchDomain: SearchDomain) => {
          this.getSportsData(item, searchDomain.UniqueCode, sportsIndex, requestObj);
        });
      });
    } else {
      this.sportsResultModel.noSearchSelect = true;
    }
  }

  // Dependancy for sports API call
  public getSportsData(sportsCategoryItem: string, searchDomain: string, sportsIndex: number, requestObj: RequestData) {
    this.sportsResultModel.sportsResults = [];
    this.sportsResultModel.compareSportsCategory = [];

    this.searchService.SearchSports(sportsCategoryItem, searchDomain, requestObj)
      .subscribe(
        data => {
          if (data["newsitems"] != null) {
            this.sportsResultModel.sportsResults = this.sportsResultModel.sportsResults.concat(data["newsitems"]);
          }
          this.sportsResultModel.filterSportsStaus = this.sportsResultModel.sportsStatus.filter(x => x.name == sportsCategoryItem)[0];
          this.sportsResultModel.filterSportsStaus.statusCount++;
          if (this.sportsResultModel.filterSportsStaus.statusCount == this.activeSearchDomains.length) {
            this.sportsResultModel.compareSportsCategory.push(sportsCategoryItem);
            this.sportsResultModel.filterSportsStaus.status = "Search completed";
            this.sportsResultModel.sportsSuccessCount++;
            this.sportsResultModel.filterSportsStaus.searchloading = false;
          }

          if (this.sportsResultModel.sportsSuccessCount == this.sportsResultModel.sportsStatus.length) {
            this.searchService.updateAudit(requestObj.profileData.searchType, requestObj.profileData.auditKey, 9, this.sportsResultModel.compareSportsCategory).subscribe();
          }

          if (this.sportsResultModel.sportsCategory.length == this.sportsResultModel.compareSportsCategory.length) {
            this.sportsResultModel.collapseSportsClass = "hide";
            this.sportsResultModel.collapseSportsStatus = "Show Status";
            this.sportsResultModel.collapseSportsIcon = "fa-chevron-down";
            this.sportsResultModel.sportsComplete = true;

            this.sportsResultModel.isLoading = false;
            if (this.sportsResultModel.sportsResults.length > 0) {
              this.sportsResultModel.sportsResults = this.getUniqueResults(this.sportsResultModel.sportsResults, 'link');
            }
            this.sportsResultModel.sportsResultCount = this.sportsResultModel.sportsResults.length;
            this.sportsResultModel.finalSportsResults = this.sportsResultModel.sportsResults;
            this.sportsResultModel.displaySportsCount = true;
          }
        },
        error => {
          console.log(error);
          if (error.status == 401 && error.headers.has('Token-Expired')) {
            this.refreshtoken.token = localStorage.getItem("access_token");
            this.searchService.refreshtoken(this.refreshtoken)
              .subscribe(
                data => {
                  localStorage.setItem("access_token", data["access_token"]);
                  localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
                  localStorage.setItem("token_type", data["token_type"]);
                  this.sportsCheck(requestObj);
                },
                error => {
                  if (error.status == 400) {
                    this.logout();
                  }
                });
          }
          else if (error.status == 502 || error.status == 504) {
            sportsIndex++;
            if (sportsIndex < 2) {
              this.getSportsData(sportsCategoryItem, searchDomain, sportsIndex, requestObj);
            }
          }
        });
  }

  public socialMediaSearch(requestObj: RequestData) {
    this.socialMediaResultModel.noSearchSelect = false;
    this.socialMediaResultModel.isLoading = false;

    if (requestObj.accessData.isSocialMediaAccess) {
      this.socialMediaResultModel.isLoading = true;
      this.socialMediaResultModel.socialMediaStatus =
        [
          { name: "facebook", displayname: "Facebook", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "instagram", displayname: "Instagram", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "linkedin", displayname: "LinkedIn", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "twitter", displayname: "Twitter", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "youtube", displayname: "YouTube", status: "Searching . . .", searchloading: true, statusCount: 0 },
          { name: "tiktok", displayname: "TikTok", status: "Searching . . .", searchloading: true, statusCount: 0 },
        ];

      this.socialMediaResultModel.collapseSocialMediaClass = "show";
      this.socialMediaResultModel.collapseSocialMediaStatus = "Hide Status";
      this.socialMediaResultModel.collapseSocialMediaIcon = "fa-chevron-up";

      this.socialMediaResultModel.socialMediaCategory.forEach((item: string) => {
        this.activeSearchDomains.forEach((searchDomain: SearchDomain) => {
          let socialMediaIndex = 0;
          this.getsocialMediaData(item, searchDomain.UniqueCode, socialMediaIndex, requestObj);
        });
      });
    } else {
      this.socialMediaResultModel.noSearchSelect = true;
    }
  }

  public getsocialMediaData(socialMediaCategoryItem: string, searchDomain: string, socialMediaIndex: number, requestObj: RequestData) {
    this.socialMediaResultModel.socialMediaResults = [];
    this.socialMediaResultModel.compareSocialMediaCategory = [];

    this.searchService.SocialMedia(socialMediaCategoryItem, searchDomain, requestObj)
      .subscribe(
        data => {
          if (data["newsitems"] != null) {
            data["newsitems"].forEach((item: Items) => {
              item.lexiconcategory = data["key"];
            });
            this.socialMediaResultModel.socialMediaResults = this.socialMediaResultModel.socialMediaResults.concat(data["newsitems"]);
          }
          this.socialMediaResultModel.filtersocialMediaStatus = this.socialMediaResultModel.socialMediaStatus.filter(x => x.name == socialMediaCategoryItem)[0];
          this.socialMediaResultModel.filtersocialMediaStatus.statusCount++

          if (this.socialMediaResultModel.filtersocialMediaStatus.statusCount == this.activeSearchDomains.length) {
            this.socialMediaResultModel.compareSocialMediaCategory.push(socialMediaCategoryItem);
            this.socialMediaResultModel.socialMediaSuccessCount++;
            this.socialMediaResultModel.filtersocialMediaStatus.status = "Search completed";
            this.socialMediaResultModel.filtersocialMediaStatus.searchloading = false;
          }
          if (this.socialMediaResultModel.socialMediaSuccessCount == this.socialMediaResultModel.socialMediaStatus.length) {
            this.searchService.updateAudit(requestObj.profileData.searchType, requestObj.profileData.auditKey, 8, this.socialMediaResultModel.compareSocialMediaCategory).subscribe();
          }

          if (this.socialMediaResultModel.socialMediaCategory.length == this.socialMediaResultModel.compareSocialMediaCategory.length) {
            this.socialMediaResultModel.collapseSocialMediaClass = "hide";
            this.socialMediaResultModel.collapseSocialMediaStatus = "Show Status";
            this.socialMediaResultModel.collapseSocialMediaIcon = "fa-chevron-down";

            this.socialMediaResultModel.isLoading = false;
            if (this.socialMediaResultModel.socialMediaResults.length > 0) {
              this.socialMediaResultModel.socialMediaResults = this.getUniqueResults(this.socialMediaResultModel.socialMediaResults, 'link');
            }

            this.socialMediaResultModel.SocialMediaResultCount = this.socialMediaResultModel.socialMediaResults.length;
            this.socialMediaResultModel.finalSocialMediaResults = this.socialMediaResultModel.socialMediaResults;

            this.socialMediaResultModel.finalSocialMediaResults.sort((a, b) => {
              if (a.lexiconcategory < b.lexiconcategory) { return -1; }
              return 0;
            });

          }
        },
        error => {
          console.log(error);
          if (error.status == 401 && error.headers.has('Token-Expired')) {
            this.refreshtoken.token = localStorage.getItem("access_token");
            this.searchService.refreshtoken(this.refreshtoken)
              .subscribe(
                data => {
                  localStorage.setItem("access_token", data["access_token"]);
                  localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
                  localStorage.setItem("token_type", data["token_type"]);
                  this.socialMediaSearch(requestObj);
                },
                error => {
                  if (error.status == 400) {
                    this.logout();
                  }
                });
          }
          else if (error.status == 502 || error.status == 504) {
            socialMediaIndex++;
            if (socialMediaIndex < 2) {
              this.getsocialMediaData(socialMediaCategoryItem, searchDomain, socialMediaIndex, requestObj);
            }
            else {
              //this.IsSocialMediaValue = true;
            }
          }
          else {
            //this.IsSocialMediaValue = true;
          }
        });
  }


  // Criminality API call
  public criminalityCheck(requestObj: RequestData): void {

    this.criminalityResultModel.noSearchSelect = false;
    if (requestObj.accessData.isCriminalityAccess) {
      this.criminalityResultModel.resultComplete = false;
      this.criminalityResultModel.collapseResultClass = "show";
      this.criminalityResultModel.collapseResultStatus = "Hide Status";
      this.criminalityResultModel.collapseResultIcon = "fa-chevron-up";
      this.criminalityResultModel.isResultValues = false;
      this.criminalityResultModel.showRelevantResults = false;
      this.criminalityResultModel.showAllResults = false;
      this.criminalityResultModel.isLoading = true;

      this.criminalityResultModel.lexiconStatus =
        [
          { name: "Risk", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Theft", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Fraud", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Driving", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Drugs", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Violence", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Crime", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Offenders", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
          { name: "Officials", status: "Searching . . .", searchloading: true, searchStatusCount: 0, analysisStatusCount: 0 },
        ];
      this.criminalityResultModel.compareLexicons = [];
      this.criminalityResultModel.rankingResults = [];
      this.criminalityResultModel.sortFinalResults = [];
      this.criminalityResultModel.relevantResults = [];
      this.criminalityResultModel.finalResults = [];
      this.criminalityResultModel.resultCount = 0;

      // --------------------------- Shuffle lexicon --------------------
      this.criminalityResultModel.lexicons = this.shuffleLexicons(this.criminalityResultModel.lexicons);

      // --------------------------- Each lexicon request --------------------
      this.criminalityResultModel.lexicons.forEach((item: string) => {
        let searchIndex: number = 0;
        this.activeSearchDomains.forEach((searchDomain) => {
          this.getData(item, searchIndex, searchDomain.UniqueCode, requestObj);
        });
      });
    } else {
      this.criminalityResultModel.noSearchSelect = true;
    }

  }

  // Dependancy for criminality API call
  public getData(lexiconKey: string, searchIndex: number, searchDomain: string, requestData: RequestData) {
    this.searchService.SearchData(lexiconKey, searchDomain, requestData)
      .subscribe(
        data => {
          if (data["id"] != null) {
            this.criminalityResultModel.searchUniqueIdlist.push(data["id"]);
            let AnalysisIndex = 0;
            this.analyseResult(this.criminalityResultModel.searchUniqueIdlist, lexiconKey, AnalysisIndex, searchDomain, requestData);
          }
          else {
            let AnalysisIndex = 0;
            this.analyseResult(this.criminalityResultModel.searchUniqueIdlist, lexiconKey, AnalysisIndex, searchDomain, requestData);
          }
        },
        error => {
          console.log(error);
          if (error.status == 401 && error.headers.has('Token-Expired')) {
            this.refreshtoken.token = localStorage.getItem("access_token");
            this.searchService.refreshtoken(this.refreshtoken)
              .subscribe(
                data => {
                  localStorage.setItem("access_token", data["access_token"]);
                  localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
                  localStorage.setItem("token_type", data["token_type"]);
                  this.getData(lexiconKey, searchIndex, searchDomain, requestData);
                },
                error => {
                  if (error.status == 400) {
                    this.logout();
                  }
                });
          }
          else {
            searchIndex++;
            if (searchIndex < 2) {
              this.getData(lexiconKey, searchIndex, searchDomain, requestData);
            }
            else {
              let AnalysisIndex = 0;
              this.analyseResult(this.criminalityResultModel.searchUniqueIdlist, lexiconKey, AnalysisIndex, searchDomain, requestData);
            }
          }
        });
  }

  // Criminality analysis API call
  public analyseResult(searchUniqueIdlist: string[], lexiconCategory: string, AnalysisIndex: number, searchDomain: string, requestData: RequestData) {
    this.criminalityResultModel.filterLexiconStatus = this.criminalityResultModel.lexiconStatus.filter(x => x.name == lexiconCategory)[0];
    this.criminalityResultModel.filterLexiconStatus.searchStatusCount++;
    if (this.criminalityResultModel.filterLexiconStatus.searchStatusCount == this.activeSearchDomains.length) {
      this.criminalityResultModel.filterLexiconStatus.status = "Searching done. Analysing results . . .";
    }

    if (searchUniqueIdlist.length == 0) {
      this.AnalyseError(lexiconCategory, requestData);
    }
    else {
      this.searchService.analyseResultRanking(searchUniqueIdlist, lexiconCategory, searchDomain, this.criminalityResultModel.devData, requestData)
        .subscribe(
          data => {
            this.criminalityResultModel.domainResults = [];
            this.criminalityResultModel.domainResults = data;

            if (this.criminalityResultModel.domainResults.length > 0) {
              this.criminalityResultModel.domainResults.forEach((result: Items) => {
                result.searchDomain = searchDomain
              });
            }

            this.criminalityResultModel.rankingResults = this.criminalityResultModel.rankingResults.concat(this.criminalityResultModel.domainResults);
            this.criminalityResultModel.filterLexiconStatus = this.criminalityResultModel.lexiconStatus.filter(x => x.name == lexiconCategory)[0];
            this.criminalityResultModel.filterLexiconStatus.analysisStatusCount++;
            if (this.criminalityResultModel.filterLexiconStatus.analysisStatusCount == this.activeSearchDomains.length) {
              this.criminalityResultModel.filterLexiconStatus.status = "Searching done. Analysis Complete.";
              this.criminalityResultModel.criminalitySuccessCount++;
              this.criminalityResultModel.filterLexiconStatus.searchloading = false;
              this.criminalityResultModel.compareLexicons.push(lexiconCategory);
              this.rankingDuplicateLinks();
            }

            if (this.criminalityResultModel.criminalitySuccessCount === this.criminalityResultModel.lexicons.length) {
              this.criminalityResultModel.isLoading = false;
              this.searchService.updateAudit(requestData.profileData.searchType, requestData.profileData.auditKey, 11, this.criminalityResultModel.compareLexicons).subscribe();
              this.RelevantResults();
            }
          },
          error => {
            console.log(error);
            if (error.status == 502 || error.status == 504) {
              AnalysisIndex++;
              if (AnalysisIndex < 2) {
                this.analyseResult(searchUniqueIdlist, lexiconCategory, AnalysisIndex, searchDomain, requestData);
              }
              else {
                this.AnalyseError(lexiconCategory, requestData);
              }
            }
            else {
              this.AnalyseError(lexiconCategory, requestData);
            }
          });
    }
  }

  // Criminality analyse api error
  public AnalyseError(lexiconCategory: string, requestObj: RequestData) {
    this.criminalityResultModel.filterLexiconStatus = this.criminalityResultModel.lexiconStatus.filter(x => x.name == lexiconCategory)[0];
    this.criminalityResultModel.filterLexiconStatus.analysisStatusCount++;

    if (this.criminalityResultModel.filterLexiconStatus.analysisStatusCount == this.activeSearchDomains.length) {
      this.criminalityResultModel.filterLexiconStatus.status = "Searching done. Analysis Complete.";
      this.criminalityResultModel.criminalitySuccessCount++;
      this.criminalityResultModel.filterLexiconStatus.analysisStatusCount++;
      this.criminalityResultModel.filterLexiconStatus.searchloading = false;
      this.criminalityResultModel.compareLexicons.push(lexiconCategory);
      this.rankingDuplicateLinks();
    }
    if (this.criminalityResultModel.criminalitySuccessCount === this.criminalityResultModel.lexicons.length) {
      this.criminalityResultModel.isLoading = false;
      this.searchService.updateAudit(requestObj.profileData.searchType, requestObj.profileData.auditKey, 11, this.criminalityResultModel.compareLexicons).subscribe();
      this.RelevantResults();
    }
  }

  // Dependancy for criminality check
  public rankingDuplicateLinks() {
    if (this.criminalityResultModel.lexicons.length == this.criminalityResultModel.compareLexicons.length) {
      this.criminalityResultModel.collapseResultClass = "hide";
      this.criminalityResultModel.collapseResultStatus = "Show Status";
      this.criminalityResultModel.collapseResultIcon = "fa-chevron-down";
      this.criminalityResultModel.resultComplete = true;

      this.criminalityResultModel.rankingResults = this.criminalityResultModel.rankingResults.sort((a, b) => b.rank - a.rank);
      this.criminalityResultModel.sortFinalResults = this.getUniqueResults(this.criminalityResultModel.rankingResults, 'link');
      this.criminalityResultModel.sortFinalResults = this.criminalityResultModel.sortFinalResults.filter((item: any) => item.rank != 0);

      this.criminalityResultModel.sortFinalResults.forEach(result => {
        this.searchDomains.forEach((item: SearchDomain) => {
          if (item.UniqueCode == result.searchDomain) {
            result.rank += item.RankValue;
          }
        });
      });

      this.criminalityResultModel.sortFinalResults.forEach((finalItem: any) => {
        this.criminalityResultModel.rankingResults.forEach((item: any) => {
          try {
            if ((item.link.indexOf(finalItem.link) >= 0) && !(finalItem.lexiconcategory.includes(item.lexiconcategory[0]))) {
              finalItem.lexiconcategory.push(item.lexiconcategory[0]);
              finalItem.rank += 100;
            }
          } catch { }
        });
      });

      this.criminalityResultModel.sortFinalResults = this.criminalityResultModel.sortFinalResults.sort((a, b) => b.rank - a.rank);
      this.criminalityResultModel.sortFinalResults.forEach((lrItem: any) => {
        if (lrItem.rank >= this.criminalityResultModel.criminalityRelevantRank) {
          this.criminalityResultModel.relevantResults.push(lrItem);
        }
      });

      this.criminalityResultModel.showAllResults = true;
      this.criminalityResultModel.isResultValues = true;

      if (this.criminalityResultModel.crimeSearchResultCount > 0) {
        this.criminalityResultModel.finalResults = this.criminalityResultModel.relevantResults.slice(0, this.criminalityResultModel.crimeSearchResultCount);
        if (this.criminalityResultModel.finalResults.length < this.criminalityResultModel.crimeSearchResultCount) {
          for (var i = 0; i < this.criminalityResultModel.sortFinalResults.length; i++) {
            if (!this.criminalityResultModel.finalResults.some(x => x.link == this.criminalityResultModel.sortFinalResults[i].link)) {
              this.criminalityResultModel.finalResults.push(this.criminalityResultModel.sortFinalResults[i]);
            }
            if (this.criminalityResultModel.finalResults.length == this.criminalityResultModel.crimeSearchResultCount)
              break;
          }
        }
      } else {
        this.criminalityResultModel.finalResults = this.criminalityResultModel.relevantResults;
      }

      this.criminalityResultModel.resultCount = this.criminalityResultModel.finalResults.length;

    }
  }

  // To show all the results for criminality
  public AllResults() {
    this.criminalityResultModel.showAllResults = false;
    this.criminalityResultModel.showRelevantResults = true;
    this.criminalityResultModel.finalResults = this.criminalityResultModel.sortFinalResults;
    this.criminalityResultModel.resultCount = this.criminalityResultModel.finalResults.length;
  }

  // To show relevant results for criminality
  public RelevantResults() {
    this.criminalityResultModel.showAllResults = true;
    this.criminalityResultModel.showRelevantResults = false;
    if (this.criminalityResultModel.crimeSearchResultCount > 0) {
      this.criminalityResultModel.finalResults = this.criminalityResultModel.relevantResults.slice(0, this.criminalityResultModel.crimeSearchResultCount);
      if (this.criminalityResultModel.finalResults.length < this.criminalityResultModel.crimeSearchResultCount) {
        for (var i = 0; i < this.criminalityResultModel.sortFinalResults.length; i++) {
          if (!this.criminalityResultModel.finalResults.some(x => x.link == this.criminalityResultModel.sortFinalResults[i].link)) {
            this.criminalityResultModel.finalResults.push(this.criminalityResultModel.sortFinalResults[i]);
          }
          if (this.criminalityResultModel.finalResults.length == this.criminalityResultModel.crimeSearchResultCount)
            break;
        }
      }
    } else {
      this.criminalityResultModel.finalResults = this.criminalityResultModel.relevantResults;
    }
    this.criminalityResultModel.resultCount = this.criminalityResultModel.finalResults.length;
  }

  // Criminality event for show all and relevant results
  public criminalityEventEmitter(value) {
    if (value == "allResult") {
      this.AllResults();
    } else if (value == "relevantResult") {
      this.RelevantResults();
    }
  }

  // Getting count of how many relevant result need to show
  public getCriminalityRelevantRank() {
    this.searchService.GetCriminalityRankForSearch().subscribe(data => {
      this.criminalityResultModel.crimeSearchResultCount = data.criminalitySearchResultCount;
      var rank = data.criminalitySearchRank;
      if (rank != null && rank != 0) {
        this.criminalityResultModel.criminalityRelevantRank = rank;
      } else {
        this.criminalityResultModel.criminalityRelevantRank = 200;
      }
    }, error => {
      this.criminalityResultModel.criminalityRelevantRank = 200;
    })
  }

  // Shuffle the lexicons list
  public shuffleLexicons(array) {
    var tmp, current, top = array.length;
    if (top) while (--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }
    return array;
  }

  // Associated API call
  public associatedCheck() {
    var requestObj: RequestData = this.prepareRequestData();
    this.associatedResultModel.profileData = requestObj.profileData;
    this.associatedResultModel.isLoading = false;
    this.associatedResultModel.responseData = [];
    this.associatedResultModel.searchFail = false;
    this.associatedResultModel.noSearchSelect = false;

    if (requestObj.accessData.isCriminalityAccess) {
      if (requestObj.profileData.associatedNames.length > 0) {
        this.associatedResultModel.isLoading = true;
        this.activeSearchDomains.forEach(x => {
          let index: number = 0;
          this.eachAssociationSearch(x, requestObj, index);
        });
      }
    } else {
      this.associatedResultModel.noSearchSelect = true;
    }

  }

  // Dependancy method for association search method
  public eachAssociationSearch(domain: SearchDomain, requestObj: RequestData, index: number) {
    this.searchService.AssociatedSearchData(domain.UniqueCode, requestObj).subscribe((data) => {
      this.associatedResultModel.searchFail = false;
      if (data["newsitems"] != null) {
        this.associatedResultModel.associatedResultCombine = this.associatedResultModel.associatedResultCombine.concat(data["newsitems"]);
      }
      this.associatedResultModel.associatedResultCount++;

      if (this.associatedResultModel.associatedResultCount == this.activeSearchDomains.length) {
        this.associatedResultModel.isLoading = false;
        if (this.associatedResultModel.associatedResultCombine.length > 0) {
          this.associatedResultModel.associatedResultCombine = this.getUniqueResults(this.associatedResultModel.associatedResultCombine, 'link');
        }
        this.associatedResultModel.responseData = this.associatedResultModel.associatedResultCombine;
      }
    }, (error) => {
      console.log(error);
      if (error.status == 401 && error.headers.has('Token-Expired')) {
        this.refreshtoken.token = localStorage.getItem("access_token");
        this.searchService.refreshtoken(this.refreshtoken)
          .subscribe(
            data => {
              localStorage.setItem("access_token", data["access_token"]);
              localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
              localStorage.setItem("token_type", data["token_type"]);
              this.associatedCheck();
            },
            error => {
              if (error.status == 400) {
                this.logout();
              }
            });
      }
      else if (error.status == 502 || error.status == 504) {
        index++;
        if (index < 2) {
          this.eachAssociationSearch(domain, requestObj, index);
        }
        else {
          this.associatedResultModel.isLoading = false;
          this.associatedResultModel.searchFail = true;
        }
      }
      else {
        this.associatedResultModel.isLoading = false;
        this.associatedResultModel.searchFail = true;
      }
    });
  }

  // Getting unique results from scraper
  public getUniqueResults(arr, link) {
    const unique = arr
      .map(e => e[link])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  // Email age API call
  public emailAgeCheck(requestObj: RequestData, debounceReason: string): void {
    this.emailageResultModel.isLoading = false;
    this.emailageResultModel.responseData = null;
    this.emailageResultModel.searchFail = false;
    this.emailageResultModel.noSearchSelect = false;
    this.emailageResultModel.validation = false;

    if (requestObj.profileData.email) {
      if (requestObj.accessData.isEmailAccess) {
        this.emailageResultModel.isLoading = true;
        this.searchService.EmailAge(requestObj, debounceReason).subscribe((data) => {
          this.emailageResultModel.isLoading = false;
          try {
            let completeJson = JSON.parse(data["data"]["completeResponse"]);
            let rawJson: string = completeJson["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"];
            completeJson["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"] = JSON.parse(rawJson.trim());
            data["data"]["completeResponse"] = completeJson;
          } catch { }
          this.emailageResultModel.responseData = data;
          var rawResult = Array.from(this.emailageResultModel.responseData["data"]["completeResponse"]["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"]["query"]["results"]);
          this.emailageResultModel.urls = [];
          rawResult.forEach(x => {
            Array.from(x["smlinks"]).forEach(y => {
              this.emailageResultModel.urls.push(y);
            });
          });
        }, (error) => {
          console.log(error);
          this.emailageResultModel.searchFail = true;
          this.emailageResultModel.isLoading = false;
        });
      } else {
        this.emailageResultModel.noSearchSelect = true;
      }
    } else {
      this.emailageResultModel.validation = true;
    }
  }

  public getEmailageOrgId() {
    this.searchService.GetEmailageOrdId().subscribe(data => {
      this.emailageOrgId = data;
    });
  }

  // Email validation check API call
  public emailValidationCheck(requestObj: RequestData): void {
    this.emailValidationResultModel.isLoading = false;
    this.emailValidationResultModel.noRelevantData = false;
    this.emailValidationResultModel.responseData = null;
    this.emailValidationResultModel.searchFail = false;
    this.emailValidationResultModel.validation = false;
    this.emailValidationResultModel.noSearchSelect = false;

    if (requestObj.accessData.isEmailAccess) {
      if (requestObj.profileData.email) {
        this.emailValidationResultModel.isLoading = true;
        this.searchService.EmailValidationCheck(requestObj).subscribe((data) => {

          var debounceReason = data["data"].debounce.reason;

          // Email Age
          this.emailAgeCheck(requestObj, debounceReason);

          this.emailValidationResultModel.isLoading = false;
          this.emailValidationResultModel.responseData = data["data"];
          if (this.emailValidationResultModel?.responseData == null) {
            this.emailValidationResultModel.noRelevantData = true;
          }
        }, (error) => {

          // Email Age
          this.emailAgeCheck(requestObj, "");

          console.log(error);
          this.emailValidationResultModel.isLoading = false;
          if (error.status === 403) {
            this.emailValidationResultModel.searchFail = true;
          }
        });
      } else {
        this.emailValidationResultModel.validation = true;
      }
    } else {
      this.emailValidationResultModel.noSearchSelect = true;
    }

  }

  // Load email age API call
  public loadEmailAgeCheck(requestObj: RequestData): void {
    this.emailageResultModel.isLoading = false;
    this.emailageResultModel.responseData = null;
    this.emailageResultModel.searchFail = false;
    this.emailageResultModel.noSearchSelect = false;

    if (requestObj.profileData.email && requestObj.accessData.isEmailAccess) {
      this.emailageResultModel.isLoading = true;
    } else {
      this.emailageResultModel.noSearchSelect = true;
    }
  }

  // Id check API call
  public idCheck(requestObj: RequestData): void {
    this.idCheckResultModel.isLoading = false;
    this.idCheckResultModel.access = requestObj.accessData;
    this.idCheckResultModel.responseData = null;
    this.idCheckResultModel.searchFail = false;
    this.idCheckResultModel.validation = false;

    if (requestObj.accessData.isIDCheckAccess) {
      if (requestObj.profileData.fullName && requestObj.profileData.email) {
        this.idCheckResultModel.isLoading = true;
        this.searchService.IDCheck(requestObj).subscribe((data) => {
          this.idCheckResultModel.isLoading = false;
          this.idCheckResultModel.responseData = data;
        }, (error) => {
          console.log(error);
          this.idCheckResultModel.isLoading = false;
          this.idCheckResultModel.searchFail = true;
        });
      } else {
        this.idCheckResultModel.validation = true;
      }
    }

  }

  // Right To Work check API call
  public rightToWorkCheck(requestObj: RequestData): void {
    this.rightToWorkResultModel.isLoading = false;
    this.rightToWorkResultModel.access = requestObj.accessData;
    this.rightToWorkResultModel.responseData = null;
    this.rightToWorkResultModel.searchFail = false;
    this.rightToWorkResultModel.validation = false;

    if (requestObj.accessData.isRTWAccess) {
      if (requestObj.profileData.fullName && requestObj.profileData.email) {
        this.rightToWorkResultModel.isLoading = true;
        this.searchService.RightToWorkSearch(requestObj).subscribe((data) => {
          this.rightToWorkResultModel.isLoading = false;
          this.rightToWorkResultModel.responseData = data;
        }, (error) => {
          console.log(error);
          this.rightToWorkResultModel.isLoading = false;
          this.rightToWorkResultModel.searchFail = true;
        });
      } else {
        this.rightToWorkResultModel.validation = true;
      }
    }

  }

  // Check about the access of Prospect hit & Access API
  public CheckProspectHitAndProfileAccess() {
    this.prospectResultModel.isProspectHitAccess = false;
    this.prospectResultModel.isProspectProfileAccess = false;
    this.searchService.CheckProsectHitAndProfileAccessClient().subscribe(data => {
      this.prospectResultModel.isProspectHitAccess = data['isProspectHitEnabled'];
      this.prospectResultModel.isProspectProfileAccess = data['isProspectProfileEnabled'];
    });
  }

  // Prospect profile request from prospect result component
  public prospectProfileRequest() {
    var requestObj: RequestData = this.prepareRequestData();
    this.prospectProfileCheck(requestObj);
  }

  // Prospect profile check API call
  public prospectProfileCheck(requestObj: RequestData): void {
    if (!this.prospectResultModel.prospectProfileButtonDisable) {
      this.confirmationService.confirm({
        key: "ProspectProfileRequest",
        message: 'In the event of any green tick(s) below, full Prospect detail can be requested and this will be provided to the requestor via email within 2 working days.',
        header: 'Confirmation',
        icon: 'pi pi-check',
        accept: () => {
          if (this.prospectResultModel.prospectHitResponseData) {
            this.prospectResultModel.isProspectProfileLoading = true;
            this.prospectResultModel.prospectProfileResponseData = null;
            this.searchService.GetProspectProfileData(requestObj).subscribe((data) => {
              this.prospectResultModel.isProspectProfileLoading = false;
              this.prospectResultModel.prospectProfileResponseData = data;
              this.prospectResultModel.prospectProfileButtonText = this.prospectProfileButtonText_Requested;
              this.prospectResultModel.prospectProfileButtonDisable = true;
            }, (error) => {
              console.log(error);
              this.prospectResultModel.isProspectProfileLoading = false;
              this.prospectResultModel.prospectProfileButtonDisable = true;
            });
          }
        },
        reject: () => {
        }
      });
    }

  }

  // Prospect hit check API call
  public prospectHitCheck(requestObj: RequestData): void {
    this.prospectResultModel.noSearchSelect = false;
    if (requestObj.accessData.isCounterFraudAccess) {
      this.prospectResultModel.isProspectHitLoading = false;
      this.prospectResultModel.isProspectProfileLoading = false;
      this.prospectResultModel.validation = false;
      this.prospectResultModel.searchFail = false;
      this.prospectResultModel.prospectHitResponseData = null;
      this.prospectResultModel.prospectProfileResponseData = null;
      this.prospectResultModel.prospectProfileButtonDisable = false;
      this.prospectResultModel.prospectProfileButtonText = this.prospectProfileButtonText_RequestProfile;

      if ((requestObj.profileData.fullName && requestObj.profileData.dateOfBirth) ||
        (requestObj.profileData.postalCode && requestObj.profileData.addressLine1) ||
        requestObj.profileData.phoneNumber || requestObj.profileData.email || requestObj.profileData.vRN) {
        this.prospectResultModel.profileData = requestObj.profileData;
        this.prospectResultModel.isProspectHitLoading = true;
        this.searchService.GetProspectHitData(requestObj).subscribe((data) => {
          this.prospectResultModel.isProspectHitLoading = false;
          this.prospectResultModel.prospectHitResponseData = data;
          this.prospectResultModel.prospectProfileButtonDisable = this.prospectResultModel.prospectHitResponseData.hits.filter(x => x.i2Hit == true).length > 0 ? false : true;
        }, (error) => {
          this.prospectResultModel.isProspectHitLoading = false;
          this.prospectResultModel.searchFail = true;
          console.log(error);
        });
      } else {
        this.prospectResultModel.validation = true;
      }
    } else {
      this.prospectResultModel.noSearchSelect = true;
    }



  }

  // Companies House API call
  public companiesHouse(requestObj: RequestData): void {
    this.companiesHouseResultModel.isLoading = false;
    this.companiesHouseResultModel.noRelevantData = false;
    this.companiesHouseResultModel.responseData = null;
    this.companiesHouseResultModel.searchFail = false;
    this.companiesHouseResultModel.noSearchSelect = false;

    if (requestObj.accessData.isCompaniesHouseAccess) {
      this.companiesHouseResultModel.isLoading = true;
      this.searchService.DirectorshipSearchData(requestObj).subscribe((data) => {
        this.companiesHouseResultModel.isLoading = false;
        this.companiesHouseResultModel.responseData = data;
        if (!this.companiesHouseResultModel.responseData || this.companiesHouseResultModel.responseData.length <= 0) {
          this.companiesHouseResultModel.noRelevantData = true;
        }
      }, (error) => {
        console.log(error);
        this.companiesHouseResultModel.isLoading = false;
        if (error.status === 403) {
          this.companiesHouseResultModel.searchFail = true;
        } else {
          this.companiesHouseResultModel.noRelevantData = true;
        }
      });
    } else {
      this.companiesHouseResultModel.noSearchSelect = true;
    }

  }

  // Financial (Lexis Nexis Person) API call
  public financialCheck(requestObj: RequestData): void {
    this.financialResultModel.isLoading = false;
    this.financialResultModel.noRelevantData = false;
    this.financialResultModel.responseData = null;
    this.financialResultModel.searchFail = false;
    this.financialResultModel.validation = false;
    this.financialResultModel.noSearchSelect = false;
    this.financialResultModel.isEmailProvided = false;
    this.financialResultModel.isMobileNumberProvided = false;
    this.ccjResultModel.isLoading = false;
    this.ccjResultModel.responseData = null;
    this.ccjResultModel.searchFail = false;
    this.ccjResultModel.validation = false;
    this.ccjResultModel.noSearchSelect = false;
    this.insolvencyResultModel.isLoading = false;
    this.insolvencyResultModel.searchFail = false;
    this.insolvencyResultModel.validation = false;
    this.insolvencyResultModel.responseData = null;
    this.insolvencyResultModel.noSearchSelect = false;
    this.addressResultModel.validation = false;
    this.addressResultModel.searchFail = false;
    this.addressResultModel.isLoading = false;
    this.addressResultModel.responseData = null;
    this.addressResultModel.noSearchSelect = false;

    if (requestObj.accessData.isFinancialAccess) {

      if (requestObj.profileData.fullName && (requestObj.profileData.postalCode || requestObj.profileData.dateOfBirth)) {
        if (requestObj.accessData.isCCJDetailAccess)
          this.ccjResultModel.isLoading = true;
        else
          this.ccjResultModel.noSearchSelect = true;

        if (requestObj.accessData.isInsolvencyDetailAccess)
          this.insolvencyResultModel.isLoading = true;
        else
          this.insolvencyResultModel.noSearchSelect = true;
      }

      if (requestObj.profileData.email) {
        this.financialResultModel.isEmailProvided = true;
      }

      if (requestObj.profileData.phoneNumber) {
        this.financialResultModel.isMobileNumberProvided = true;
      }

      if (requestObj.profileData.fullName && (requestObj.profileData.postalCode || requestObj.profileData.dateOfBirth)) {

        if (requestObj.accessData.isAddressAccess) {
          this.addressResultModel.isLoading = true;
        }
        this.financialResultModel.isLoading = true;
        this.searchService.GetPersonSearch(requestObj).subscribe((data) => {
          this.financialResultModel.isLoading = false;
          this.financialResultModel.responseData = JSON.parse(JSON.stringify(data));
          if (requestObj.accessData.isAddressAccess) {
            this.addressResultModel.isLoading = false;
            this.addressResultModel.responseData = data;
          }
          if (!this.financialResultModel.responseData) {
            this.financialResultModel.noRelevantData = true;
          } else {
            if (this.financialResultModel?.responseData?.data?.InsolvencyFlag == true || this.financialResultModel?.responseData?.data?.CcjFlag == true) {
              this.ccjAndInsolvencyCheck(requestObj, data);
            } else {
              this.ccjResultModel.isLoading = false;
              this.insolvencyResultModel.isLoading = false;
            }
          }
        }, (error) => {
          this.financialResultModel.isLoading = false;
          if (error.status === 403) {
            this.financialResultModel.searchFail = true;
            if (requestObj.accessData.isAddressAccess) {
              this.addressResultModel.searchFail = true;
            }
          }
          if (requestObj.accessData.isAddressAccess) {
            this.addressResultModel.isLoading = false;
          }
          console.log(error);
        });
      } else {
        if (requestObj.accessData.isFinancialAccess) {
          this.financialResultModel.validation = true;
        }
        if (requestObj.accessData.isAddressAccess) {
          this.addressResultModel.validation = true;
        }
        if (requestObj.accessData.isInsolvencyDetailAccess) {
          this.insolvencyResultModel.validation = true;
        }
        if (requestObj.accessData.isCCJDetailAccess) {
          this.ccjResultModel.validation = true;
        }
      }
    } else {
      this.financialResultModel.noSearchSelect = true;
      if (!requestObj.accessData.isCCJDetailAccess) {
        this.ccjResultModel.noSearchSelect = true;
      }
      if (!requestObj.accessData.isInsolvencyDetailAccess) {
        this.insolvencyResultModel.noSearchSelect = true;
      }
      if (!requestObj.accessData.isAddressAccess) {
        this.addressResultModel.noSearchSelect = true;
      }
    }

  }


  // CCJ & Insolvency (Lexis Nexis IDU) API call
  public ccjAndInsolvencyCheck(requestObj: RequestData, financialData: any): void {
    if (requestObj.accessData.isFinancialAccess &&
      (requestObj.accessData.isCCJDetailAccess || requestObj.accessData.isInsolvencyDetailAccess)) {

      if (requestObj.profileData.fullName && (requestObj.profileData.postalCode || requestObj.profileData.dateOfBirth)) {
        this.searchService.SearchCCJAndInsolvency(requestObj, financialData).subscribe((data) => {
          if (requestObj.accessData.isCCJDetailAccess)
            this.ccjResultModel.isLoading = false;
          if (requestObj.accessData.isInsolvencyDetailAccess)
            this.insolvencyResultModel.isLoading = false;

          var json = JSON.parse(JSON.stringify(data));
          if (json.status == 200) {
            json.data.completeResponse = JSON.parse(json.data.completeResponse);

            if (requestObj.accessData.isCCJDetailAccess)
              this.ccjResultModel.responseData = json.data;
            if (requestObj.accessData.isInsolvencyDetailAccess)
              this.insolvencyResultModel.responseData = json.data;

          } else {
            if (requestObj.accessData.isCCJDetailAccess)
              this.ccjResultModel.searchFail = true;
            if (requestObj.accessData.isInsolvencyDetailAccess)
              this.insolvencyResultModel.searchFail = true;
          }
        }, (error) => {
          console.log(error);
          if (requestObj.accessData.isCCJDetailAccess) {
            this.ccjResultModel.isLoading = false;
            this.ccjResultModel.searchFail = true;
          }
          if (requestObj.accessData.isInsolvencyDetailAccess) {
            this.insolvencyResultModel.isLoading = false;
            this.insolvencyResultModel.searchFail = true;
          }
        });
      }
      else {
        if (requestObj.accessData.isCCJDetailAccess)
          this.ccjResultModel.validation = true;

        if (requestObj.accessData.isInsolvencyDetailAccess)
          this.insolvencyResultModel.validation = true;
      }

    }
  }

  // Counter fraud (Prospect) API call
  public counterFraudCheck(requestObj: RequestData): void {
    this.counterFraudResultModel.isLoading = false;
    this.counterFraudResultModel.noRelevantData = false;
    this.counterFraudResultModel.responseData = null;
    this.counterFraudResultModel.searchFail = false;
    this.counterFraudResultModel.validationMessage = false;
    this.counterFraudResultModel.noSearchSelect = false;

    if (requestObj.accessData.isCounterFraudAccess) {
      if (requestObj.profileData.fullName && (requestObj.profileData.postalCode || requestObj.profileData.dateOfBirth ||
        requestObj.profileData.email || requestObj.profileData.phoneNumber || requestObj.profileData.vRN || requestObj.profileData.companyName)) {
        this.counterFraudResultModel.isLoading = true;
        this.searchService.GetCounterFraudData(requestObj).subscribe((data) => {
          this.counterFraudResultModel.isLoading = false;
          this.counterFraudResultModel.responseData = JSON.parse(JSON.stringify(data));
          if (this.counterFraudResultModel.responseData && this.counterFraudResultModel.responseData.status === 200 && this.counterFraudResultModel.responseData.data.ragStatus) {
            this.counterFraudResultModel.noRelevantData = false;
            const color = this.counterFraudResultModel.responseData.data.ragStatus;
            if (color.toLowerCase() === 'green') {
              this.counterFraudResultModel.needleValue = 17;
            }
            if (color.toLowerCase() === 'amber') {
              this.counterFraudResultModel.needleValue = 50;
            }
            if (color.toLowerCase() === 'red') {
              this.counterFraudResultModel.needleValue = 85;
            }
          } else {
            this.counterFraudResultModel.noRelevantData = true;
          }
        }, (error) => {
          console.log(error);
          if (error.status === 403) {
            this.counterFraudResultModel.searchFail = true;
          }
          this.counterFraudResultModel.isLoading = false;
        });
      }
    } else {
      this.counterFraudResultModel.noSearchSelect = true;
    }

  }

  // Email check API call
  public emailCheck(requestObj: RequestData): void {
    this.emailResultModel.isLoading = false;
    this.emailResultModel.noRelevantData = false;
    this.emailResultModel.responseData = null;
    this.emailResultModel.searchFail = false;
    this.emailResultModel.validation = false;
    this.emailResultModel.noSearchSelect = false;

    if (requestObj.accessData.isEmailAccess) {
      if (requestObj.profileData.email) {
        this.emailResultModel.isLoading = true;
        this.searchService.EmailDomainCheck(requestObj).subscribe((data) => {
          this.emailResultModel.isLoading = false;
          this.emailResultModel.responseData = data;
          if (this.emailResultModel?.responseData?.data == null) {
            this.emailResultModel.noRelevantData = true;
          }
        }, (error) => {
          console.log(error);
          this.emailResultModel.isLoading = false;
          if (error.status === 403) {
            this.emailResultModel.searchFail = true;
          }
        });
      } else {
        this.emailResultModel.validation = true;
      }
    } else {
      this.emailResultModel.noSearchSelect = true;
    }

  }

  // Telisign check API call
  public telisignCheck(requestObj: RequestData): void {
    this.mobileResultModel.isLoading = false;
    this.mobileResultModel.responseData = null;
    this.mobileResultModel.searchFail = false;
    this.mobileResultModel.validationError = false;
    this.mobileResultModel.noSearchSelect = false;

    if (requestObj.accessData.isMobileAccess) {
      if (requestObj.profileData.phoneNumber) {
        this.mobileResultModel.isLoading = true;
        this.searchService.GetMobileData(requestObj).subscribe((data) => {
          this.mobileResultModel.isLoading = false;
          this.mobileResultModel.responseData = data;
        }, (error) => {
          console.log(error);
          if (error.status === 403) {
            this.mobileResultModel.searchFail = true;
          }
          this.mobileResultModel.isLoading = false;
        });
      } else {
        this.mobileResultModel.validationError = true;
      }
    } else {
      this.mobileResultModel.noSearchSelect = true;
    }

  }

  // AML check API call
  public amlCheck(requestObj: RequestData): void {
    this.amlResultModel.isLoading = false;
    this.amlResultModel.searchFail = false;
    this.amlResultModel.responseData = null;
    this.amlResultModel.noSearchSelect = false;

    if (requestObj.accessData.isAMLAccess) {
      if (requestObj.profileData.fullName) {
        this.amlResultModel.isLoading = true;
        this.searchService.SearchAML(requestObj).subscribe((data) => {
          this.amlResultModel.isLoading = false;
          this.amlResultModel.responseData = data;
        }, (error) => {
          console.log(error);
          this.amlResultModel.searchFail = true;
          this.amlResultModel.isLoading = false;
        })
      }
    } else {
      this.amlResultModel.noSearchSelect = true;
    }

  }

  // Reset the result objects
  public resetResultObjects() {
    this.addressResultModel = new AddressResultModel();
    this.amlResultModel = new AMLResultModel();
    this.ccjResultModel = new CCJResultModel();
    this.insolvencyResultModel = new InsolvencyResultModel();
    this.mobileResultModel = new MobileResultModel();
    this.counterFraudResultModel = new CounterFraudResultModel();
    this.emailResultModel = new EmailCheckResultModel();
    this.emailageResultModel = new EmailageCheckResultModel();
    this.rightToWorkResultModel = new RightToWorkCheckResultModel();
    this.idCheckResultModel = new IdCheckResultModel();
    this.financialResultModel = new FinancialResultModel();
    this.companiesHouseResultModel = new CompaniesHouseResultModel();
    this.associatedResultModel = new AssociatedResultModel();
    this.criminalityResultModel = new CriminalityResultModel();
    this.sportsResultModel = new SportsResultModel();
    this.piplResultModel = new PIPLResultModel();
    this.socialMediaResultModel = new SocialMediaResultModel();
    this.landRegistryResultModel = new LandRegistryResultModel();

    this.prospectResultModel.isProspectHitLoading = false;
    this.prospectResultModel.isProspectProfileLoading = false;
    this.prospectResultModel.validation = false;
    this.prospectResultModel.searchFail = false;
    this.prospectResultModel.prospectHitResponseData = null;
    this.prospectResultModel.prospectProfileResponseData = null;
    this.prospectResultModel.prospectProfileButtonDisable = false;
    this.prospectResultModel.prospectProfileButtonText = "";
  }

  public onEmailChange(): void {
    this.personalForm.get('profile.email').clearValidators();
    this.personalForm.get('profile.email').setValidators(this.validationType['email']);
    this.personalForm.get('profile.email').updateValueAndValidity();
  }

  public onMobileChange() {
    this.personalForm.get('profile.mobile').clearValidators();
    this.personalForm.get('profile.mobile').setValidators(this.validationType['mobile']);
    this.personalForm.get('profile.mobile').updateValueAndValidity();
  }

  public onPostCodeChange() {
    this.personalForm.get('profile.postcode').clearValidators();
    this.personalForm.get('profile.postcode').setValidators(this.validationType['postcode']);
    this.personalForm.get('profile.postcode').updateValueAndValidity();
  }

  // Reset the form and clear the validations on 'clear' button click
  public personalClearSearch(): void {
    this.personalForm.controls['profile'].reset();
    this.clearValidators();
    this.showResultTab = false;
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const topControls in this.personalForm.controls) {
      if (topControls == 'profile') {
        let childControls = this.personalForm.controls[topControls] as FormGroup
        for (const child in childControls.controls) {
          try {
            this.personalForm.get(topControls + "." + child).clearValidators();
            this.personalForm.get(topControls + "." + child).setValidators(this.validationType[child]);
            this.personalForm.get(topControls + "." + child).updateValueAndValidity();
          } catch (err) { }
        }
      }
    }
  }

  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public clearValidators(): void {
    this.personalForm.patchValue({
      audit: "",
      reference: "",
      name: "",
      postcode: "",
      mobile: "",
      email: "",
      dob: "",
      address: "",
      associations: this.formBuilder.array([])
    });
    for (const topControls in this.personalForm.controls) {
      if (topControls == 'profile') {
        let childControls = this.personalForm.controls[topControls] as FormGroup
        for (const child in childControls.controls) {
          try {
            this.personalForm.get(topControls + "." + child).clearValidators();
            this.personalForm.get(topControls + "." + child).updateValueAndValidity();
          } catch (err) { }
        }
      }
    }
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.personalForm.get('profile.audit').value;
    profileData.referenceNumber = this.personalForm.get('profile.reference').value;
    profileData.fullName = this.personalForm.get('profile.name').value.trim();
    if (this.personalForm.get('profile.name').value) {
      let searchtextarray = this.personalForm.get('profile.name').value.trim().split(" ");
      profileData.firstName = searchtextarray[0];
      profileData.lastName = searchtextarray.length > 1 ? searchtextarray[searchtextarray.length - 1] : searchtextarray[1];
      profileData.midleName = searchtextarray.length > 2 ? searchtextarray[1] : null;
    } else {
      profileData.firstName = null;
      profileData.lastName = null;
      profileData.midleName = null;
    }
    profileData.postalCode = this.personalForm.get('profile.postcode').value;
    if (this.personalForm.get('profile.mobile').value) {
      profileData.phoneNumber = this.personalForm.get('profile.mobile').value;
      const phoneNumber = parsePhoneNumber(profileData.phoneNumber, 'GB');
      if (phoneNumber.isValid() === true && isPossiblePhoneNumber(profileData.phoneNumber, 'GB') === true
        && isValidPhoneNumber(profileData.phoneNumber, 'GB') && validatePhoneNumberLength(profileData.phoneNumber, 'GB') === undefined) {
        profileData.phoneNumber = phoneNumber.formatInternational();
      }
    }
    profileData.email = this.personalForm.get('profile.email').value;
    profileData.dateOfBirth = this.personalForm.get('profile.dob').value;
    profileData.addressLine1 = this.personalForm.get('profile.address').value;

    this.associationEntities.controls.forEach(x => {
      if (x.get('name').value) {
        const obj = {
          name: x.get('name').value
        }
        profileData.associatedNames.push(obj);
      }
    });
    profileData.searchDate = Shared.getCurrentDate();
    profileData.searchTime = Shared.getCurrentTime();
    profileData.searchType = SearchType.Personal.toString();
    profileData.searchUsername = this.loggedInUsername;

    // Access object framing
    var accessData = new AccessData();
    accessData = this.accessData;
    accessData.isFinancialAccess = this.personalForm.get('access.financial').value;
    accessData.isCriminalityAccess = !this.personalForm.get('access.quick').value;
    accessData.isQuickSearchEnabled = this.personalForm.get('access.quick').value;
    accessData.isLandRegistryAccess = this.personalForm.get('access.landregistry').value;

    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.profileData = profileData;
    requestObj.accessData = accessData;
    return requestObj;
  }

  public logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("token_type");
    localStorage.removeItem("userId");
    localStorage.removeItem("subscription");
    this.router.navigate(['/Login']);
  }

}
