<div class="card-body">
  <div>

    <div class="row mb-3">
      <div class="col-md-6 p-0 text-right">
        Financial
      </div>
      <div class="col-md-6 p-0 text-left">
        <div>
          <div class="loadingImg" *ngIf="business.isLoading || companiesHouse.isLoading">
            <img src="{{messageService.loadingAnimation}}" />
          </div>

          <div *ngIf="(!business.isLoading && business?.responseData) || (!companiesHouse.isLoading && companiesHouse.responseData)">
            <!-- Limited Company -->
            <div *ngIf="isLimitedCompany()">
              <div *ngIf="(business?.responseData?.creditsafeResult?.report?.negativeInformation?.ccjSummary?.exactRegistered > 0 || business?.responseData?.creditsafeResult?.report?.negativeInformation?.ccjSummary?.numberOfSatisfied > 0 || companiesHouse?.responseData?.completeResponse?.has_insolvency_history); else default1">
                <span class="ml-3 dot dot-red"></span>
              </div>
              <ng-template #default1>
                <div>
                  <span class="ml-3 dot dot-green"></span>
                </div>
              </ng-template>
            </div>

            <!-- Non Limited Company -->
            <div *ngIf="isNonLimitedCompany()">
              <div *ngIf="(business?.responseData?.businessNonRegisteredProfileResult?.Identification?.ConsumerCreditLicenceSummary?.NocFlag == 'Y'); else default2">
                <span class="ml-3 dot dot-red"></span>
              </div>
              <ng-template #default2>
                <div>
                  <span class="ml-3 dot dot-green"></span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6 p-0 text-right">
        Regulatory
      </div>
      <div class="col-md-6 p-0 text-left">
        <div>
          <div class="loadingImg" *ngIf="business.isLoading">
            <img src="{{messageService.loadingAnimation}}" />
          </div>

          <div *ngIf="!business.isLoading && business?.responseData">
            <!-- Limited -->
            <div *ngIf="isLimitedCompany()">
              <div *ngIf="(haveDisqualifiedDirectors() || companiesHouse?.responseData?.completeResponse?.accounts?.overdue == true || companiesHouse?.responseData?.completeResponse?.company_status_detail == 'active-proposal-to-strike-off')">
                <span class="ml-3 dot dot-red"></span>
              </div>
              <div *ngIf="!(haveDisqualifiedDirectors() || companiesHouse?.responseData?.completeResponse?.accounts?.overdue == true || companiesHouse?.responseData?.completeResponse?.company_status_detail == 'active-proposal-to-strike-off')">
                <span class="ml-3 dot dot-green"></span>
              </div>
            </div>

            <!-- Non Limited -->
            <div *ngIf="isNonLimitedCompany()">
              <span class="ml-3 dot dot-white"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-6 p-0 text-right">
        Fraud
      </div>
      <div class="col-md-6 p-0 text-left">
        <div>
          <div class="loadingImg" *ngIf="business?.isLoading || prospect?.isProspectHitLoading">
            <img src="{{messageService.loadingAnimation}}" />
          </div>
          <div *ngIf="!business?.isLoading && !prospect?.isProspectHitLoading">
            <!-- Limited -->
            <div *ngIf="isLimitedCompany()">
              <div *ngIf="((prospect?.prospectHitResponseData && !prospect?.prospectProfileButtonDisable) || companiesHouse?.responseData?.completeResponse?.registered_office_is_in_dispute == true); else default3">
                <span class="ml-3 dot dot-red"></span>
              </div>
              <ng-template #default3>
                <div>
                  <span class="ml-3 dot dot-green"></span>
                </div>
              </ng-template>
            </div>

            <!-- Non Limited -->
            <div *ngIf="isNonLimitedCompany()">
              <div *ngIf="(prospect?.prospectHitResponseData && !prospect?.prospectProfileButtonDisable); else default3">
                <span class="ml-3 dot dot-red"></span>
              </div>
              <ng-template #default3>
                <div>
                  <span class="ml-3 dot dot-green"></span>
                </div>
              </ng-template>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
