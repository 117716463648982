<div class="col-md-12" *ngIf="prospect.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelect}}</i></div>
<div class="col-md-12" *ngIf="!prospect.noSearchSelect && prospect.isProspectHitAccess && !prospect.isProspectHitLoading && !prospect.validation && prospect.searchFail && (!prospect.prospectHitResponseData || (prospect.prospectHitResponseData?.errormessages?.length > 0))"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
<div class="col-md-12" *ngIf="!prospect.noSearchSelect && prospect.isProspectHitAccess && !prospect.isProspectHitLoading && !prospect.searchFail && !prospect.validation && (prospect.prospectHitResponseData?.hits == null || (prospect.prospectHitResponseData?.hits?.length == 0 && prospect.prospectHitResponseData?.errormessages?.length == 0))"><i class="color-grey float-right">{{messageService.NoRelevantDataMessage}}</i></div>
<div class="col-md-12" *ngIf="!prospect.noSearchSelect && prospect.isProspectHitAccess && !prospect.isProspectHitLoading && !prospect.searchFail && prospect.validation"><i class="color-grey float-right">{{messageService.OtherFieldRequired}}</i></div>
<div>
  <span class="loadingImg" *ngIf="prospect.isProspectHitLoading">
    <img src="{{messageService.loadingAnimation}}" />
  </span>
</div>
<div class="col-md-12" *ngIf="prospect.isProspectHitAccess && !prospect.searchFail && !prospect.validation && prospect.prospectHitResponseData && !prospect.isProspectHitLoading && !(prospect.prospectHitResponseData?.hits == null || prospect.prospectHitResponseData?.hits.length == 0)">
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive keep-together">
        <div *ngIf="prospect.isProspectProfileAccess" class="my-3">
          <p-button [icon]="!prospect.isProspectProfileLoading ? 'pi pi-check' : 'pi pi-spin pi-spinner'" [disabled]="prospect.prospectProfileButtonDisable || prospect.isProspectProfileLoading" (click)="RequestProspectProfileData()" label="{{prospect.prospectProfileButtonText}}"></p-button>
          <div *ngIf="prospect.prospectProfileResponseData?.reference">
            Reference Number : {{prospect.prospectProfileResponseData.reference}}
          </div>
        </div>
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Details</th>
              <th>I2 Match</th>
              <th>Previous Search</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let searchData of prospect?.prospectHitResponseData?.hits">
              <td>
                <div *ngIf="searchData?.searchType == 'Person'">
                  <p class="mb-1">Person name: {{prospect?.profileData?.fullName}}</p>
                  <p class="mb-1">Date of birth: {{prospect?.profileData?.dateOfBirth}}</p>
                </div>
                <div *ngIf="searchData?.searchType == 'Telephone'">
                  <p class="mb-1">Telephone: {{prospect?.profileData?.phoneNumber}}</p>
                </div>
                <div *ngIf="searchData?.searchType == 'Email'">
                  <p class="mb-1">Email: {{prospect?.profileData?.email}}</p>
                </div>
                <div *ngIf="searchData?.searchType == 'Vehicle'">
                  <p class="mb-1">Vehicle number: {{prospect?.profileData?.vRN}}</p>
                </div>
                <div *ngIf="searchData?.searchType == 'Address'">
                  <p class="mb-1">Address: {{prospect?.profileData?.addressLine1}}</p>
                </div>
              </td>
              <td>
                <div *ngIf="searchData?.i2Hit == true">
                  <span><i class="fa fa-check-circle color-green"></i></span>
                </div>
                <div *ngIf="searchData?.i2Hit == false">
                  <span><i class="fa fa-times-circle color-grey"></i></span>
                </div>
              </td>
              <td>
                <div *ngIf="searchData?.previousHit == true">
                  <span><i class="fa fa-check-circle color-green"></i></span>
                </div>
                <div *ngIf="searchData?.previousHit == false">
                  <span><i class="fa fa-times-circle color-grey"></i></span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
