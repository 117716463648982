import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { MessagingService } from '../../../services/messaging.service';
import { SearchService } from '../../../services/search.service';
import { AddressResultModel } from '../../models/AddressResultModel';
import { BusinessOpenSourceResultModel, BusinessResultModel } from '../../models/BusinessResultModel';
import { CompaniesHouseResultModel } from '../../models/CompaniesHouseResultModel';
import { CounterFraudResultModel, ProspectResultModel } from '../../models/CounterFraudResultModel';
import { EmailageCheckResultModel, EmailCheckResultModel } from '../../models/EmailResultModel';
import { Item, SearchDomain, Token } from '../../models/Items';
import { MobileResultModel } from '../../models/MobileResultModel';
import { AccessData, ProfileData, RequestData } from '../../models/SearchRequestModel';
import { SearchType } from '../../models/SearchType';
import { VATResultModel } from '../../models/VATResultModel';
import { isPossiblePhoneNumber, parsePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { Shared } from '../../../services/shared';
import { AppComponent } from '../../../../../app.component';
import { LandRegistryResultModel } from '../../models/LandRegistryResultModel';
declare var $: any;

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css', '../shared/shared.searchpage.css']
})
export class BusinessComponent implements OnInit {
  @ViewChild('pdf') pdf: PDFExportComponent;

  public companiesNumberRegex = /^((AC|ZC|FC|GE|LP|OC|SE|SA|SZ|SF|GS|SL|SO|SC|ES|NA|NZ|NF|GN|NL|NC|R0|NI|EN|\d{2}|SG|FE)\d{5}(\d|C|R))|((RS|SO)\d{3}(\d{3}|\d{2}[WSRCZF]|\d(FI|RS|SA|IP|US|EN|AS)|CUS))|((NI|SL)\d{5}[\dA])|(OC(([\dP]{5}[CWERTB])|([\dP]{4}(OC|CU))))$/;
  public businessPostcodeRegex = /^(([gG][iI][rR] {0,}0[aA]{2})|(([aA][sS][cC][nN]|[sS][tT][hH][lL]|[tT][dD][cC][uU]|[bB][bB][nN][dD]|[bB][iI][qQ][qQ]|[fF][iI][qQ][qQ]|[pP][cC][rR][nN]|[sS][iI][qQ][qQ]|[iT][kK][cC][aA]) {0,}1[zZ]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yxA-HK-XY]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;
  public businessForm: FormGroup;
  public validation: any = {
    'reference': Validators.required,
    'email': Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$'),
    'mobile': Validators.pattern("^[0-9]{10,}$"),
    'companyNumber': Validators.pattern(this.companiesNumberRegex),
    'required': Validators.required,
    'businessPostcode': Validators.pattern(this.businessPostcodeRegex)
  };

  // PDF
  public requestDataModel: RequestData = new RequestData();

  public accessData: AccessData = new AccessData();
  public showResultTab: boolean = false;
  public emailageOrgId: string;
  public prospectProfileButtonText_RequestProfile = "Request Profile";
  public prospectProfileButtonText_Requested = "Requested";
  public searchDomains: SearchDomain[] = [];
  public activeSearchDomains: SearchDomain[] = [];
  public refreshtoken = new Token(null);
  public saveAsPDFButtonVisibility: boolean = true;
  public clearSearchButtonVisibility: boolean = true;
  public loggedInUsername: string = "";

  public requestData: RequestData = new RequestData();
  public emailValidationResultModel: EmailCheckResultModel = new EmailCheckResultModel()
  public emailageResultModel: EmailageCheckResultModel = new EmailageCheckResultModel();
  public mobileResultModel: MobileResultModel = new MobileResultModel();
  public prospectResultModel: ProspectResultModel = new ProspectResultModel();
  public opensourceResult: BusinessOpenSourceResultModel = new BusinessOpenSourceResultModel();
  public vatResult: VATResultModel = new VATResultModel();
  public businessResult: BusinessResultModel = new BusinessResultModel();
  public addressResultModel: AddressResultModel = new AddressResultModel();
  public counterFraudResultModel: CounterFraudResultModel = new CounterFraudResultModel();
  public companiesHouseResultModel: CompaniesHouseResultModel = new CompaniesHouseResultModel();
  public landRegistryResultModel: LandRegistryResultModel = new LandRegistryResultModel();

  constructor(private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private searchService: SearchService,
    private messagingService: MessagingService,
    private confirmationService: ConfirmationService,
    private router: Router,
    public appComponent: AppComponent
  ) { }

  ngOnInit(): void {
    let isLoggedIn = localStorage.getItem("access_token") ? true : false;
    if (!isLoggedIn)
      this.router.navigate(['/Login']);
    this.searchService.GetAllowedSearchAvailablityItems().subscribe(data => {
      let keys = Array.from(data).map(x => x.key);
      if (!keys.find(x => x == "BUSINESS")) {
        this.router.navigate(['/v3']);
      }
    }, error => {
      console.log(error);
    })

    this.businessForm = this.formBuilder.group({
      audit: [""],
      reference: [""],
      companyNumber: [""],
      businessName: [""],
      businessPostcode: [""],
      vatNumber: [""],
      email: [""],
      mobile: [""],
      name: [""],
      postcode: [""],
      dob: [""]
    });
    this.saveAsPDFButtonVisibility = false;
    this.clearSearchButtonVisibility = false;
    this.getLicenceItems();
    this.CheckProspectHitAndProfileAccess();
    this.getEmailageOrgId();
    this.GetSearchDomainJson();
    this.getUserDetails();
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/new/Background/HUT-elem-bg-1.png)';
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundAttachment = 'fixed';
  }

  public generatePDF() {
    var requestObj: RequestData = this.prepareRequestData();
    this.requestDataModel = requestObj;

    setTimeout(() => {
      this.pdf.forcePageBreak = ".page-break"
      this.pdf.keepTogether = ".keep-together";
      this.pdf.paperSize = "A4";
      const margin = {
        top: '2cm',
        bottom: '3cm',
        left: '1cm',
        right: '1cm'
      }
      this.pdf.margin = margin;
      this.pdf.scale = 0.5;
      this.pdf.saveAs('export.pdf');
    }, 5000);
  }

  // Getting search domain json for scraping
  public GetSearchDomainJson() {
    this.searchService.getDomainJson()
      .subscribe((data) => {
        this.searchDomains = data;
        this.activeSearchDomains = data.filter(x => x.IsActive == true);
      }, error => {
        console.log(error);
      });
  }

  public getUserDetails() {
    this.searchService.getUserDetails().subscribe(data => {
      this.loggedInUsername = data.username;
    }, error => {
      console.log(error);
    })
  }

  // Search button click event
  public businessSearch(): void {
    this.showResultTab = false;

    this.addValidators();
    this.businessForm.updateValueAndValidity();

    this.validationCheck();

    if (this.businessForm.valid) {
      Promise.resolve(this.getRandomString(10)).then(
        (data) => {
          this.saveAsPDFButtonVisibility = true;
          this.clearSearchButtonVisibility = true;

          this.resetResultObjects();

          this.showResultTab = true;
          var requestObj: RequestData = this.prepareRequestData();
          this.requestData = this.prepareRequestData();

          this.setActiveResultTab();

          // Land Registry
          this.landRegistry(requestObj);

          // Telisign Check
          this.telisignCheck(requestObj);

          // Email Validation
          this.emailValidationCheck(requestObj);

          // Load Emailage
          this.loadEmailAgeCheck(requestObj);

          if (this.prospectResultModel.isProspectHitAccess) {
            // Prospect Hit Check
            this.prospectHitCheck(requestObj);
          }

          // Business Open Source Check
          this.openSourceBusinessSearch(requestObj);

          // VAT Check
          this.vatCheck(requestObj);

          // Business Detail
          this.businessDetailSearch(requestObj);

          // Proprietor Search
          this.proprietorSearch(requestObj);

          //Counter Fraud Check
          this.counterFraudCheck(requestObj);

          // Companies House Search
          this.companiesHouse(requestObj);
        }
      );
    }
  }

  public landRegistry(requestObj: RequestData): void {
    this.landRegistryResultModel.isLoading = false;
    this.landRegistryResultModel.noRelevantData = false;
    this.landRegistryResultModel.noSearchSelect = false;
    this.landRegistryResultModel.responseData = null;
    this.landRegistryResultModel.searchFail = false;
    this.landRegistryResultModel.validation = false;

    if(!requestObj.accessData.isLandRegistryAccess){
      this.landRegistryResultModel.noSearchSelect = true;
      return;
    }

    if (requestObj.profileData.companyNumber) {
      this.landRegistryResultModel.isLoading = true;
      this.searchService.LandRegistryBusinessResult(requestObj).subscribe(data => {
        this.landRegistryResultModel.isLoading = false;
        
        if(!data){
          this.landRegistryResultModel.noRelevantData = true;
          return;
        }

        if(data.status == 200)
          this.landRegistryResultModel.responseData = data.data;
      }, error => {
        this.landRegistryResultModel.isLoading = false;
        this.landRegistryResultModel.searchFail = true;
        console.log(error);
      });
    } else {
      this.landRegistryResultModel.validation = true;
    }
    
  }

  public setActiveResultTab() {
    setTimeout(() => {
      // Tab Header
      var navLine = $('.nav-link');
      $.each(navLine, function (index, x) {
        if ($(x).hasClass("active")) {
          $(x).removeClass("active")
        }
      })

      // Tab Result
      var tabPane = $('.tab-pane');
      $.each(tabPane, function (index, x) {
        if ($(x).hasClass("active")) {
          $(x).removeClass("active")
        }
      })

      // Show first tab as active
      $('#business-search-tab').addClass("active");
      $('#businessSearch').addClass("active");
    }, 2000);
  }


  // Companies House API call
  public companiesHouse(requestObj: RequestData): void {
    this.companiesHouseResultModel.isLoading = false;
    this.companiesHouseResultModel.noRelevantData = false;
    this.companiesHouseResultModel.responseData = null;
    this.companiesHouseResultModel.searchFail = false;
    this.companiesHouseResultModel.noSearchSelect = false;

    if (requestObj.profileData.companyNumber) {
      this.companiesHouseResultModel.isLoading = true;
      this.searchService.GetCompaniesHouse(requestObj).subscribe((data) => {
        data["data"]["completeResponse"] = JSON.parse(data["data"]["completeResponse"]);
        this.companiesHouseResultModel.isLoading = false;
        this.companiesHouseResultModel.responseData = data["data"];
        if (!this.companiesHouseResultModel.responseData || this.companiesHouseResultModel.responseData.length <= 0) {
          this.companiesHouseResultModel.noRelevantData = true;
        }
      }, (error) => {
        console.log(error);
        this.companiesHouseResultModel.isLoading = false;
        if (error.status === 403) {
          this.companiesHouseResultModel.searchFail = true;
        } else {
          this.companiesHouseResultModel.noRelevantData = true;
        }
      });
    } else {
      this.companiesHouseResultModel.noSearchSelect = true;
    }

  }

  // Counter fraud (Prospect) API call
  public counterFraudCheck(requestObj: RequestData): void {
    this.counterFraudResultModel.isLoading = false;
    this.counterFraudResultModel.noRelevantData = false;
    this.counterFraudResultModel.responseData = null;
    this.counterFraudResultModel.searchFail = false;
    this.counterFraudResultModel.validationMessage = false;
    this.counterFraudResultModel.noSearchSelect = false;
    if (requestObj.accessData.isCounterFraudAccess) {
      if ((requestObj.profileData.fullName || requestObj.profileData.postalCode || requestObj.profileData.dateOfBirth ||
        requestObj.profileData.email || requestObj.profileData.phoneNumber || requestObj.profileData.vRN || requestObj.profileData.companyName)) {
        this.counterFraudResultModel.isLoading = true;
        this.searchService.GetCounterFraudData(requestObj).subscribe((data) => {
          this.counterFraudResultModel.isLoading = false;
          this.counterFraudResultModel.responseData = JSON.parse(JSON.stringify(data));
          if (this.counterFraudResultModel.responseData && this.counterFraudResultModel.responseData.status === 200 && this.counterFraudResultModel.responseData.data.ragStatus) {
            this.counterFraudResultModel.noRelevantData = false;
            const color = this.counterFraudResultModel.responseData.data.ragStatus;
            if (color.toLowerCase() === 'green') {
              this.counterFraudResultModel.needleValue = 17;
            }
            if (color.toLowerCase() === 'amber') {
              this.counterFraudResultModel.needleValue = 50;
            }
            if (color.toLowerCase() === 'red') {
              this.counterFraudResultModel.needleValue = 85;
            }
          } else {
            this.counterFraudResultModel.noRelevantData = true;
          }
        }, (error) => {
          console.log(error);
          if (error.status === 403) {
            this.counterFraudResultModel.searchFail = true;
          }
          this.counterFraudResultModel.isLoading = false;
        });
      }
    } else {
      this.counterFraudResultModel.noSearchSelect = true;
    }
  }

  public proprietorSearch(requestObj: RequestData): void {
    this.addressResultModel.validation = false;
    this.addressResultModel.searchFail = false;
    this.addressResultModel.isLoading = false;
    this.addressResultModel.responseData = null;
    this.addressResultModel.noSearchSelect = false;

    if (requestObj.profileData.fullName && (requestObj.profileData.dateOfBirth || requestObj.profileData.postalCode)) {
      this.addressResultModel.isLoading = true;
      this.searchService.GetPersonSearch(requestObj).subscribe(data => {
        this.addressResultModel.isLoading = false;
        this.addressResultModel.responseData = data;
      }, error => {
        console.log(error);
        this.addressResultModel.isLoading = false;
        this.addressResultModel.searchFail = true;
      })
    } else {
      this.addressResultModel.validation = true;
    }
  }

  public businessDetailSearch(requestObj: RequestData): void {
    this.businessResult.isLoading = false;
    this.businessResult.noRelevantData = false;
    this.businessResult.noSearchSelect = false;
    this.businessResult.responseData = null;
    this.businessResult.searchFail = false;

    this.businessResult.isLoading = true;
    this.searchService.BusinessSearch(requestObj).subscribe(data => {
      this.businessResult.isLoading = false;
      if (data["businessTargeterResult"])
        data["businessTargeterResult"] = JSON.parse(data["businessTargeterResult"]);
      if (data["creditsafeResult"])
        data["creditsafeResult"] = JSON.parse(data["creditsafeResult"]);
      if (data["businessNonRegisteredProfileResult"])
        data["businessNonRegisteredProfileResult"] = JSON.parse(data["businessNonRegisteredProfileResult"]);
      this.businessResult.responseData = data;

      this.businessResult.businessTargeterResult = data["businessTargeterResult"] ? data["businessTargeterResult"] : null;
      this.businessResult.creditsafeResult = data["creditsafeResult"] ? data["creditsafeResult"] : null;
      this.businessResult.businessNonRegisteredProfileResult = data["businessNonRegisteredProfileResult"] ? data["businessNonRegisteredProfileResult"] : null;

      if (!this.businessResult.responseData.businessNonRegisteredProfileResult && !this.businessResult.responseData.creditsafeResult) {
        this.businessResult.noRelevantData = true;
      }

      let companyName = "";
      let companyPostcode = "";
      let companyNumber = requestObj.profileData.companyNumber;

      if (this.businessResult.responseData?.creditsafeResult?.report?.companyIdentification?.basicInformation?.contactAddress?.postalCode)
        companyPostcode = this.businessResult.responseData?.creditsafeResult?.report?.companyIdentification?.basicInformation?.contactAddress?.postalCode;
      else if (this.businessResult.responseData?.businessNonRegisteredProfileResult?.identification?.businessLocation?.postcode)
        companyPostcode = this.businessResult.responseData?.businessNonRegisteredProfileResult?.identification?.businessLocation?.postcode

      if (this.businessResult.responseData?.creditsafeResult?.report?.companyIdentification?.basicInformation?.registeredCompanyName)
        companyName = this.businessResult.responseData?.creditsafeResult?.report?.companyIdentification?.basicInformation?.registeredCompanyName;
      else if (this.businessResult.responseData?.businessNonRegisteredProfileResult?.businessName)
        companyName = this.businessResult.responseData?.businessNonRegisteredProfileResult?.businessName

      if (!requestObj.profileData.companyNumber)
        companyNumber = this.businessResult?.responseData?.creditsafeResult?.report?.companySummary?.companyRegistrationNumber;

      if ((!requestObj.profileData.companyName || !requestObj.profileData.companyPostalCode) &&
        (companyName && companyPostcode)) {
        requestObj.profileData.companyName = companyName;
        requestObj.profileData.companyPostalCode = companyPostcode;
        this.openSourceBusinessSearch(requestObj);
      }

      if (!requestObj.profileData.companyNumber) {
        requestObj.profileData.companyNumber = companyNumber;
        this.companiesHouse(requestObj);
        this.landRegistry(requestObj);
      }
    }, error => {
      this.businessResult.isLoading = false;
      this.businessResult.searchFail = true;
      console.log(error);
    })
  }

  public vatCheck(requestObj: RequestData): void {
    this.vatResult.isLoading = false;
    this.vatResult.noRelevantData = false;
    this.vatResult.responseData = null;
    this.vatResult.searchFail = false;
    this.vatResult.validation = false;

    if (requestObj.profileData.vatNumber) {
      this.vatResult.isLoading = true;
      this.searchService.VatSearch(requestObj).subscribe(data => {
        this.vatResult.isLoading = false;
        if (data["status"] == 200) {
          data = JSON.parse(data["data"]);
          this.vatResult.responseData = data;
        } else {
          this.vatResult.noRelevantData = true;
        }
      }, error => {
        this.vatResult.isLoading = false;
        this.vatResult.searchFail = true;
        console.log(error);
      });
    } else {
      this.vatResult.validation = true;
    }

  }

  // Open source check for business search
  public openSourceBusinessSearch(requestObj: RequestData): void {
    this.opensourceResult.isLoading = false;
    this.opensourceResult.responseData = [];
    this.opensourceResult.searchFail = false;
    this.opensourceResult.noSearchSelect = false;

    if (requestObj.profileData.companyName && requestObj.profileData.companyPostalCode) {
      this.opensourceResult.isLoading = true;
      this.activeSearchDomains.forEach(x => {
        let index: number = 0;
        this.eachOpenSourceSearch(x, requestObj, index);
      });
    }
  }

  // Dependancy method for business open source search method
  public eachOpenSourceSearch(domain: SearchDomain, requestObj: RequestData, index: number) {
    this.searchService.BusinessOpenSourceSearch(domain.UniqueCode, requestObj).subscribe((data) => {
      this.opensourceResult.searchFail = false;
      if (data["newsitems"] != null) {
        this.opensourceResult.openSourceResultCombine = this.opensourceResult.openSourceResultCombine.concat(data["newsitems"]);
      }
      this.opensourceResult.openSourceResultCount++;

      if (this.opensourceResult.openSourceResultCount == this.activeSearchDomains.length) {
        this.opensourceResult.isLoading = false;
        if (this.opensourceResult.openSourceResultCombine.length > 0) {
          this.opensourceResult.openSourceResultCombine = this.getUniqueResults(this.opensourceResult.openSourceResultCombine, 'link');
        }
        this.opensourceResult.responseData = this.opensourceResult.openSourceResultCombine;
      }
    }, (error) => {
      console.log(error);
      if (error.status == 401 && error.headers.has('Token-Expired')) {
        this.refreshtoken.token = localStorage.getItem("access_token");
        this.searchService.refreshtoken(this.refreshtoken)
          .subscribe(
            data => {
              localStorage.setItem("access_token", data["access_token"]);
              localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
              localStorage.setItem("token_type", data["token_type"]);
              this.openSourceBusinessSearch(requestObj);
            },
            error => {
              if (error.status == 400) {
                this.appComponent.logout();
              }
            });
      }
      else if (error.status == 502 || error.status == 504) {
        index++;
        if (index < 2) {
          this.eachOpenSourceSearch(domain, requestObj, index);
        }
        else {
          this.opensourceResult.isLoading = false;
          this.opensourceResult.searchFail = true;
        }
      }
      else {
        this.opensourceResult.isLoading = false;
        this.opensourceResult.searchFail = true;
      }
    });
  }

  // Getting unique results from scraper
  public getUniqueResults(arr, link) {
    const unique = arr
      .map(e => e[link])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  // Check about the access of Prospect hit & Access API
  public CheckProspectHitAndProfileAccess() {
    this.prospectResultModel.isProspectHitAccess = false;
    this.prospectResultModel.isProspectProfileAccess = false;
    this.searchService.CheckProsectHitAndProfileAccessClient().subscribe(data => {
      this.prospectResultModel.isProspectHitAccess = data['isProspectHitEnabled'];
      this.prospectResultModel.isProspectProfileAccess = data['isProspectProfileEnabled'];
    });
  }

  // Prospect profile request from prospect result component
  public prospectProfileRequest() {
    var requestObj: RequestData = this.prepareRequestData();
    this.prospectProfileCheck(requestObj);
  }

  // Prospect profile check API call
  public prospectProfileCheck(requestObj: RequestData): void {
    if (!this.prospectResultModel.prospectProfileButtonDisable) {
      this.confirmationService.confirm({
        key: "ProspectProfileRequest",
        message: 'In the event of any green tick(s) below, full Prospect detail can be requested and this will be provided to the requestor via email within 2 working days.',
        header: 'Confirmation',
        icon: 'pi pi-check',
        accept: () => {
          if (this.prospectResultModel.prospectHitResponseData) {
            this.prospectResultModel.isProspectProfileLoading = true;
            this.prospectResultModel.prospectProfileResponseData = null;
            this.searchService.GetProspectProfileData(requestObj).subscribe((data) => {
              this.prospectResultModel.isProspectProfileLoading = false;
              this.prospectResultModel.prospectProfileResponseData = data;
              this.prospectResultModel.prospectProfileButtonText = this.prospectProfileButtonText_Requested;
              this.prospectResultModel.prospectProfileButtonDisable = true;
            }, (error) => {
              console.log(error);
              this.prospectResultModel.isProspectProfileLoading = false;
              this.prospectResultModel.prospectProfileButtonDisable = true;
            });
          }
        },
        reject: () => {
        }
      });
    }

  }

  // Prospect hit check API call
  public prospectHitCheck(requestObj: RequestData): void {
    this.prospectResultModel.noSearchSelect = false;
    if (requestObj.accessData.isCounterFraudAccess) {
      this.prospectResultModel.isProspectHitLoading = false;
      this.prospectResultModel.isProspectProfileLoading = false;
      this.prospectResultModel.validation = false;
      this.prospectResultModel.searchFail = false;
      this.prospectResultModel.prospectHitResponseData = null;
      this.prospectResultModel.prospectProfileResponseData = null;
      this.prospectResultModel.prospectProfileButtonDisable = false;
      this.prospectResultModel.prospectProfileButtonText = this.prospectProfileButtonText_RequestProfile;

      if ((requestObj.profileData.fullName && requestObj.profileData.dateOfBirth) ||
        (requestObj.profileData.postalCode && requestObj.profileData.addressLine1) ||
        requestObj.profileData.phoneNumber || requestObj.profileData.email || requestObj.profileData.vRN) {
        this.prospectResultModel.profileData = requestObj.profileData;
        this.prospectResultModel.isProspectHitLoading = true;
        this.searchService.GetProspectHitData(requestObj).subscribe((data) => {
          this.prospectResultModel.isProspectHitLoading = false;
          this.prospectResultModel.prospectHitResponseData = data;
          this.prospectResultModel.prospectProfileButtonDisable = this.prospectResultModel.prospectHitResponseData.hits.filter(x => x.i2Hit == true).length > 0 ? false : true;
        }, (error) => {
          this.prospectResultModel.isProspectHitLoading = false;
          this.prospectResultModel.searchFail = true;
          console.log(error);
        });
      } else {
        this.prospectResultModel.validation = true;
      }
    } else {
      this.prospectResultModel.noSearchSelect = true;
    }
  }


  // Telisign check API call
  public telisignCheck(requestObj: RequestData): void {
    this.mobileResultModel.isLoading = false;
    this.mobileResultModel.responseData = null;
    this.mobileResultModel.searchFail = false;
    this.mobileResultModel.validationError = false;
    this.mobileResultModel.noSearchSelect = false;

    if (requestObj.accessData.isMobileAccess) {
      if (requestObj.profileData.phoneNumber) {
        this.mobileResultModel.isLoading = true;
        this.searchService.GetMobileData(requestObj).subscribe((data) => {
          this.mobileResultModel.isLoading = false;
          this.mobileResultModel.responseData = data;
        }, (error) => {
          console.log(error);
          if (error.status === 403) {
            this.mobileResultModel.searchFail = true;
          }
          this.mobileResultModel.isLoading = false;
        });
      } else {
        this.mobileResultModel.validationError = true;
      }
    } else {
      this.mobileResultModel.noSearchSelect = true;
    }

  }

  // Email age API call
  public emailAgeCheck(requestObj: RequestData, debounceReason: string): void {
    this.emailageResultModel.isLoading = false;
    this.emailageResultModel.responseData = null;
    this.emailageResultModel.searchFail = false;
    this.emailageResultModel.noSearchSelect = false;
    this.emailageResultModel.validation = false;

    if (requestObj.accessData.isEmailAccess) {
      if (requestObj.profileData.email) {
        this.emailageResultModel.isLoading = true;
        this.searchService.EmailAge(requestObj, "").subscribe((data) => {
          this.emailageResultModel.isLoading = false;
          try {
            let completeJson = JSON.parse(data["data"]["completeResponse"]);
            let rawJson: string = completeJson["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"];
            completeJson["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"] = JSON.parse(rawJson.trim());
            data["data"]["completeResponse"] = completeJson;
          } catch { }
          this.emailageResultModel.responseData = data;
          var rawResult = Array.from(this.emailageResultModel.responseData["data"]["completeResponse"]["integration_hub_results"][this.emailageOrgId + ":emailage"]["Emailage"]["tps_vendor_raw_response"]["query"]["results"]);
          this.emailageResultModel.urls = [];
          rawResult.forEach(x => {
            Array.from(x["smlinks"]).forEach(y => {
              this.emailageResultModel.urls.push(y);
            });
          });
        }, (error) => {
          console.log(error);
          this.emailageResultModel.searchFail = true;
          this.emailageResultModel.isLoading = false;
        });
      } else {
        this.emailageResultModel.validation = true;
      }
    } else {
      this.emailageResultModel.noSearchSelect = true;
    }
  }

  public getEmailageOrgId() {
    this.searchService.GetEmailageOrdId().subscribe(data => {
      this.emailageOrgId = data;
    });
  }

  // Email validation check API call
  public emailValidationCheck(requestObj: RequestData): void {
    this.emailValidationResultModel.isLoading = false;
    this.emailValidationResultModel.noRelevantData = false;
    this.emailValidationResultModel.responseData = null;
    this.emailValidationResultModel.searchFail = false;
    this.emailValidationResultModel.validation = false;
    this.emailValidationResultModel.noSearchSelect = false;

    if (requestObj.accessData.isEmailAccess) {
      if (requestObj.profileData.email) {
        this.emailValidationResultModel.isLoading = true;
        this.searchService.EmailValidationCheck(requestObj).subscribe((data) => {

          var debounceReason = data["data"].debounce.reason;

          // Email Age
          this.emailAgeCheck(requestObj, debounceReason);

          this.emailValidationResultModel.isLoading = false;
          this.emailValidationResultModel.responseData = data["data"];
          if (this.emailValidationResultModel?.responseData == null) {
            this.emailValidationResultModel.noRelevantData = true;
          }
        }, (error) => {

          // Email Age
          this.emailAgeCheck(requestObj, "");

          console.log(error);
          this.emailValidationResultModel.isLoading = false;
          if (error.status === 403) {
            this.emailValidationResultModel.searchFail = true;
          }
        });
      } else {
        this.emailValidationResultModel.validation = true;
      }
    } else {
      this.emailValidationResultModel.noSearchSelect = true;
    }
  }

  // Load email age API call
  public loadEmailAgeCheck(requestObj: RequestData): void {
    this.emailageResultModel.isLoading = false;
    this.emailageResultModel.responseData = null;
    this.emailageResultModel.searchFail = false;
    this.emailageResultModel.noSearchSelect = false;

    if (requestObj.profileData.email && requestObj.accessData.isEmailAccess) {
      this.emailageResultModel.isLoading = true;
    } else {
      this.emailageResultModel.noSearchSelect = true;
    }
  }

  public async getRandomString(length) {
    await this.searchService.GetAuditKey().then(
      data => {
        this.businessForm.patchValue({
          audit: data
        });
      });
  }

  public onMobileChange(): void {
    this.businessForm.get('mobile').clearValidators();
    this.businessForm.get('mobile').setValidators(this.validation['mobile']);
    this.businessForm.get('mobile').updateValueAndValidity();
  }

  public onEmailChange(): void {
    this.businessForm.get('email').clearValidators();
    this.businessForm.get('email').setValidators(this.validation['email']);
    this.businessForm.get('email').updateValueAndValidity();
  }

  public onCompanyNumberChange(): void {
    this.businessForm.get('companyNumber').clearValidators();
    this.businessForm.get('companyNumber').setValidators(this.validation['companyNumber']);
    this.businessForm.get('companyNumber').updateValueAndValidity();
    this.validationCheck();
  }

  public onCompanyNameChange(): void {
    this.validationCheck();
  }

  public onCompanyPostcodeChange(): void {
    this.businessForm.get('businessPostcode').clearValidators();
    this.businessForm.get('businessPostcode').setValidators(this.validation['businessPostcode']);
    this.businessForm.get('businessPostcode').updateValueAndValidity();
    //this.validationCheck();
  }

  public resetResultObjects(): void {
    this.emailageResultModel = new EmailageCheckResultModel();
    this.emailValidationResultModel = new EmailCheckResultModel();
    this.mobileResultModel = new MobileResultModel();
    this.opensourceResult = new BusinessOpenSourceResultModel();
    this.vatResult = new VATResultModel();
    this.businessResult = new BusinessResultModel();

    this.prospectResultModel.isProspectHitLoading = false;
    this.prospectResultModel.isProspectProfileLoading = false;
    this.prospectResultModel.validation = false;
    this.prospectResultModel.searchFail = false;
    this.prospectResultModel.prospectHitResponseData = null;
    this.prospectResultModel.prospectProfileResponseData = null;
    this.prospectResultModel.prospectProfileButtonDisable = false;
    this.prospectResultModel.prospectProfileButtonText = "";
  }

  // TODO: this validation need to done through custom validation
  public validationCheck() {

    let companyNumber = this.businessForm.get('companyNumber').value;
    let companyName = this.businessForm.get('businessName').value;
    //let companyPostcode = this.businessForm.get('businessPostcode').value;

    if (companyNumber && companyName) {
      this.businessForm.get('companyNumber').clearValidators();
      this.businessForm.get('companyNumber').setValidators([this.validation['companyNumber']]);
      this.businessForm.get('companyNumber').updateValueAndValidity();

      //this.businessForm.get('businessPostcode').clearValidators();
      //this.businessForm.get('businessPostcode').setValidators(this.validation['businessPostcode']);
      //this.businessForm.get('businessPostcode').updateValueAndValidity();

      this.businessForm.get('businessName').clearValidators();
      this.businessForm.get('businessName').updateValueAndValidity();

      return;
    }

    if (companyNumber) {
      this.businessForm.get('companyNumber').clearValidators();
      this.businessForm.get('companyNumber').setValidators([this.validation['companyNumber'], this.validation['required']]);
      this.businessForm.get('companyNumber').updateValueAndValidity();

      this.businessForm.get('businessName').clearValidators();
      this.businessForm.get('businessName').updateValueAndValidity();

      //this.businessForm.get('businessPostcode').clearValidators();
      //this.businessForm.get('businessPostcode').setValidators(this.validation['businessPostcode']);
      //this.businessForm.get('businessPostcode').updateValueAndValidity();

    } else if (companyName) {

      this.businessForm.get('businessName').clearValidators();
      this.businessForm.get('businessName').setValidators(this.validation['required']);
      this.businessForm.get('businessName').updateValueAndValidity();

      //this.businessForm.get('businessPostcode').clearValidators();
      //this.businessForm.get('businessPostcode').setValidators([this.validation['required'], this.validation['businessPostcode']]);
      //this.businessForm.get('businessPostcode').updateValueAndValidity();

      this.businessForm.get('companyNumber').clearValidators();
      this.businessForm.get('companyNumber').updateValueAndValidity();
      if (companyNumber) {
        this.businessForm.get('companyNumber').setValidators([this.validation['companyNumber']]);
        this.businessForm.get('companyNumber').updateValueAndValidity();
      }

    } else if (!companyNumber && !companyName) {

      this.businessForm.get('businessName').clearValidators();
      this.businessForm.get('businessName').setValidators(this.validation['required']);
      this.businessForm.get('businessName').updateValueAndValidity();

      this.businessForm.get('companyNumber').clearValidators();
      this.businessForm.get('companyNumber').setValidators(this.validation['required']);
      this.businessForm.get('companyNumber').updateValueAndValidity();

      //this.businessForm.get('businessPostcode').clearValidators();
      //this.businessForm.get('businessPostcode').setValidators(this.validation['required']);
      //this.businessForm.get('businessPostcode').updateValueAndValidity();

    } else {

      this.businessForm.get('businessName').clearValidators();
      this.businessForm.get('businessName').updateValueAndValidity();

      this.businessForm.get('companyNumber').clearValidators();
      this.businessForm.get('companyNumber').updateValueAndValidity();

      //this.businessForm.get('businessPostcode').clearValidators();
      //this.businessForm.get('businessPostcode').updateValueAndValidity();

    }

  }

  // Prepare the request object for API call
  public prepareRequestData(): RequestData {
    // Profile object framing
    var profileData = new ProfileData();
    profileData.auditKey = this.businessForm.get('audit').value;
    profileData.referenceNumber = this.businessForm.get('reference').value;
    profileData.companyNumber = this.businessForm.get('companyNumber').value;
    profileData.companyName = this.businessForm.get('businessName').value;
    profileData.companyPostalCode = this.businessForm.get('businessPostcode').value;
    profileData.vatNumber = this.businessForm.get('vatNumber').value;
    profileData.email = this.businessForm.get('email').value;
    profileData.phoneNumber = this.businessForm.get('mobile').value;

    if (this.businessForm.get('mobile').value) {
      profileData.phoneNumber = this.businessForm.get('mobile').value;
      const phoneNumber = parsePhoneNumber(profileData.phoneNumber, 'GB');
      if (phoneNumber.isValid() === true && isPossiblePhoneNumber(profileData.phoneNumber, 'GB') === true
        && isValidPhoneNumber(profileData.phoneNumber, 'GB') && validatePhoneNumberLength(profileData.phoneNumber, 'GB') === undefined) {
        profileData.phoneNumber = phoneNumber.formatInternational();
      }
    }

    profileData.fullName = this.businessForm.get('name').value;
    profileData.dateOfBirth = this.businessForm.get('dob').value;
    profileData.postalCode = this.businessForm.get('postcode').value;

    if (this.businessForm.get('name').value) {
      let searchtextarray = this.businessForm.get('name').value.split(" ");
      profileData.firstName = searchtextarray[0];
      profileData.lastName = searchtextarray.length > 1 ? searchtextarray[searchtextarray.length - 1] : searchtextarray[1];
      profileData.midleName = searchtextarray.length > 2 ? searchtextarray[1] : null;
    } else {
      profileData.firstName = null;
      profileData.lastName = null;
      profileData.midleName = null;
    }

    profileData.searchType = SearchType.Business.toString();
    profileData.searchDate = Shared.getCurrentDate();
    profileData.searchTime = Shared.getCurrentTime();
    profileData.searchUsername = this.loggedInUsername;

    // RequestData object framing
    var requestObj = new RequestData();
    requestObj.profileData = profileData;
    requestObj.accessData = this.accessData;
    return requestObj;
  }

  // Add the validations during the 'search' button click
  public addValidators(): void {
    for (const control in this.businessForm.controls) {
      this.businessForm.get(control).clearValidators();
      this.businessForm.get(control).setValidators(this.validation[control]);
      this.businessForm.get(control).updateValueAndValidity();
    }
  }

  // Clear the validations during the 'clear' button click
  // This'll remove all the error message
  public businessClearSearch(): void {
    for (const control in this.businessForm.controls) {
      this.businessForm.get(control).clearValidators();
      this.businessForm.get(control).updateValueAndValidity();
    }
    //this.businessForm.reset();
    this.businessForm.patchValue({
      audit: "",
      reference: "",
      companyNumber: "",
      businessName: "",
      businessPostcode: "",
      vatNumber: "",
      email: "",
      mobile: "",
      name: "",
      postcode: "",
      dob: ""
    });
    this.showResultTab = false;
    this.resetResultObjects();
  }

  public getLicenceItems(): void {
    this.searchService.AllowedLicence().subscribe(data => {
      this.getSecondAccess(data);
    });
  }

  public getSecondAccess(Items: Item[]) {
    Items.forEach(item => {
      if (item.licenceType.toLowerCase() == "search") {
        switch ((item.key)) {
          case "HUTDB": {
            this.accessData.isHUTDatabaseAccess = item.defaultValue;
            this.accessData.isHUTDatabaseToggle = item.toggle;
            break;
          }
          case "MOBILE": {
            this.accessData.isMobileAccess = item.defaultValue;
            this.accessData.isMobileToggle = item.toggle;
            break;
          }
          case "EMAIL": {
            this.accessData.isEmailAccess = item.defaultValue;
            this.accessData.isEmailToggle = item.toggle;
            break;
          }
          case "IDENTITY": {
            this.accessData.isIdentityAccess = item.defaultValue;
            this.accessData.isIdentityToggle = item.toggle;
            break;
          }
          case "FINANCIAL": {
            this.accessData.isFinancialAccess = item.defaultValue;
            this.accessData.isFinancialToggle = item.toggle;
            break;
          }
          case "ADDRESS": {
            this.accessData.isAddressAccess = item.defaultValue;
            this.accessData.isAddressToggle = item.toggle;
            break;
          }
          case "CRIMINALITY": {
            this.accessData.isCriminalityAccess = item.defaultValue;
            this.accessData.isCriminalityToggle = item.toggle;
            break;
          }
          case "SOCIAL": {
            this.accessData.isSocialMediaAccess = item.defaultValue;
            this.accessData.isSocialMediaToggle = item.toggle;
            break;
          }
          case "COUNTER_FRAUD": {
            this.accessData.isCounterFraudAccess = item.defaultValue;
            this.accessData.isCounterFraudToggle = item.toggle;
            break;
          }
          case "SPORTS": {
            this.accessData.isSportsAccess = item.defaultValue;
            this.accessData.isSportsToggle = item.toggle;
            break;
          }
          case "ADVERT": {
            this.accessData.isAdvertsAccess = item.defaultValue;
            this.accessData.isAdvertsToggle = item.toggle;
            break;
          }
          case "COMPANY_HOUSE": {
            this.accessData.isCompaniesHouseAccess = item.defaultValue;
            this.accessData.isCompaniesHouseToggle = item.toggle;
            break;
          }
          case "AVA": {
            this.accessData.isAVAAccess = item.defaultValue;
            this.accessData.isAVAToggle = item.toggle;
            break;
          }
          case "AML": {
            this.accessData.isAMLAccess = item.defaultValue;
            this.accessData.isAMLToggle = item.toggle;
            break;
          }
          case "CCJ_Detail": {
            this.accessData.isCCJDetailAccess = item.defaultValue;
            this.accessData.isCCJDetailToggle = item.toggle;
            break;
          }
          case "Insolvency_Detail": {
            this.accessData.isInsolvencyDetailAccess = item.defaultValue;
            this.accessData.isInsolvencyDetailToggle = item.toggle;
            break;
          }
          case "RTW": {
            this.accessData.isRTWAccess = item.defaultValue;
            this.accessData.isRTWToggle = item.toggle;
            break;
          }
          case "IDCHECK": {
            this.accessData.isIDCheckAccess = item.defaultValue;
            this.accessData.isIDCheckToggle = item.toggle;
            break;
          }
          case "LANDREGISTRY": {
            this.accessData.isLandRegistryAccess = item.defaultValue;
            this.accessData.isLandRegistryToggle = item.toggle;
            break;
          }
        }
      }
    });
  }

}
