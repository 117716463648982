<h4>Current Officers</h4>
<p-table [value]="business?.responseData?.creditsafeResult?.report?.directors?.currentDirectors" dataKey="name" [expandedRowKeys]="currentDirectorExpand()">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem"></th>
      <th pSortableColumn="name">Officer Name <p-sortIcon field="name"></p-sortIcon></th>
      <th pSortableColumn="nationality">Nationality <p-sortIcon field="nationality"></p-sortIcon></th>
      <th pSortableColumn="dateOfBirth">Date Of Birth <p-sortIcon field="dateOfBirth"></p-sortIcon></th>
      <th pSortableColumn="directorType">Officer Type <p-sortIcon field="directorType"></p-sortIcon></th>
      <th pSortableColumn="role">Role <p-sortIcon field="role"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-director let-expanded="expanded">
    <tr>
      <td>
        <button type="button" pButton pRipple [pRowToggler]="director" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td>{{director?.name}}</td>
      <td>{{director?.nationality}}</td>
      <td>{{director?.dateOfBirth | date:'MMM-yyyy'}}</td>
      <td>{{director?.directorType}}</td>
      <td>
        <div *ngFor="let position of director?.positions">
          <div>{{position?.positionName}}</div>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-director>
    <tr>
      <td colspan="6">
        <div class="row">
          <div class="col-md-4">
            <div><b>Title</b></div>
            <div>{{director?.title}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Gender</b></div>
            <div>{{director?.gender}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Occupation</b></div>
            <div>{{director?.additionalData?.occupation}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Date Appointed</b></div>
            <div>
              <div *ngFor="let position of director?.positions">
                <div>{{position?.dateAppointed | date:'dd-MMM-yyyy'}}</div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div><b>Number of Current Appointments</b></div>
            <div>{{director?.additionalData?.presentAppointments}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Disqualified</b></div>
            <div>{{director?.additionalData?.disqualified ? 'Yes' : 'No'}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Disqualified Exception</b></div>
            <div>{{director?.additionalData?.disqualifiedException ? 'Yes' : 'No'}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Officer Address Type</b></div>
            <div>{{director?.address?.type}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Officer Address</b></div>
            <div>{{director?.address?.simpleValue}}</div>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<br />

<h4>Previous Officers</h4>
<p-table [value]="business?.responseData?.creditsafeResult?.report?.directors?.previousDirectors" dataKey="name" [expandedRowKeys]="previousDirectorExpand()">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem"></th>
      <th pSortableColumn="name">Previous Officer Name <p-sortIcon field="name"></p-sortIcon></th>
      <th pSortableColumn="nationality">Nationality <p-sortIcon field="nationality"></p-sortIcon></th>
      <th pSortableColumn="dateOfBirth">Date Of Birth <p-sortIcon field="dateOfBirth"></p-sortIcon></th>
      <th pSortableColumn="directorType">Officer Type <p-sortIcon field="directorType"></p-sortIcon></th>
      <th pSortableColumn="role">Previous Role <p-sortIcon field="role"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-director let-expanded="expanded">
    <tr>
      <td>
        <button type="button" pButton pRipple [pRowToggler]="director" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td>{{director?.name}}</td>
      <td>{{director?.nationality}}</td>
      <td>{{director?.dateOfBirth | date:'MMM-yyyy'}}</td>
      <td>{{director?.directorType}}</td>
      <td>
        <div *ngFor="let position of director?.positions">
          <div>{{position?.positionName}}</div>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-director>
    <tr>
      <td colspan="6">
        <div class="row">
          <div class="col-md-4">
            <div><b>Title</b></div>
            <div>{{director?.title}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Gender</b></div>
            <div>{{director?.gender}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Previous Occupation</b></div>
            <div>{{director?.additionalData?.occupation}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Resignation Date</b></div>
            <div>{{director?.resignationDate | date:'dd-MMM-yyyy'}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Number of Current Appointments</b></div>
            <div>{{director?.additionalData?.presentAppointments}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Number of Previous Appointments</b></div>
            <div>{{director?.additionalData?.previousAppointments}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Number of Dissolved Appointments</b></div>
            <div>{{director?.additionalData?.dissolvedAppointments}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Officer Address Type</b></div>
            <div>{{director?.address?.type}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Officer Address</b></div>
            <div>{{director?.address?.simpleValue}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Disqualified</b></div>
            <div>{{director?.additionalData?.disqualified ? 'Yes' : 'No'}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Disqualified Exception</b></div>
            <div>{{director?.additionalData?.disqualifiedException ? 'Yes' : 'No'}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Date Appointed</b></div>
            <div>
              <div *ngFor="let position of director?.positions">
                {{position?.dateAppointed | date:'dd-MMM-yyyy'}}
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<br />

<h4>Active PSC</h4>
<p-table [value]="business?.responseData?.creditsafeResult?.report?.additionalInformation?.personsWithSignificantControl?.activePSC" dataKey="name" [expandedRowKeys]="activePSCExpand()">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem"></th>
      <th pSortableColumn="name">Name Of Person With Significant Control(PSC) <p-sortIcon field="name"></p-sortIcon></th>
      <th pSortableColumn="nationality">Nationality <p-sortIcon field="nationality"></p-sortIcon></th>
      <th pSortableColumn="dateOfBirth">Date Of Birth <p-sortIcon field="dateOfBirth"></p-sortIcon></th>
      <th pSortableColumn="countryOfResidence">Country Of Residence <p-sortIcon field="countryOfResidence"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-person let-expanded="expanded">
    <tr>
      <td>
        <button type="button" pButton pRipple [pRowToggler]="person" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td>{{person?.name}}</td>
      <td>{{person?.nationality}}</td>
      <td>{{person?.dateOfBirth | date:'dd-MMM-yyyy'}}</td>
      <td>{{person?.countryOfResidence}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-person>
    <tr>
      <td colspan="5">
        <div class="row">
          <div class="col-md-4">
            <div><b>Title</b></div>
            <div>{{person?.title}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Person Type</b></div>
            <div>{{person?.personType}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Appointment Date</b></div>
            <div>{{person?.notifiedOn | date:'dd-MMM-yyyy'}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Nature Of Control</b></div>
            <div>{{person?.natureOfControl}}</div>
          </div>
          <div class="col-md-4">
            <div><b>PSC Address</b></div>
            <div>{{person?.address?.simpleValue}}</div>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<br />

<h3>Ceased PSC</h3>
<p-table [value]="business?.responseData?.creditsafeResult?.report?.additionalInformation?.personsWithSignificantControl?.ceasedPSC" dataKey="name" [expandedRowKeys]="ceasedPSCExpand()">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem"></th>
      <th pSortableColumn="name">Name Of Previous Person With Significant Control (PSC) <p-sortIcon field="name"></p-sortIcon></th>
      <th pSortableColumn="nationality">Nationality <p-sortIcon field="nationality"></p-sortIcon></th>
      <th pSortableColumn="dateOfBirth">Date Of Birth <p-sortIcon field="dateOfBirth"></p-sortIcon></th>
      <th pSortableColumn="countryOfResidence">Country Of Residence <p-sortIcon field="countryOfResidence"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-person let-expanded="expanded">
    <tr>
      <td>
        <button type="button" pButton pRipple [pRowToggler]="person" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td>{{person?.name}}</td>
      <td>{{person?.nationality}}</td>
      <td>{{person?.dateOfBirth | date:'dd-MMM-yyyy'}}</td>
      <td>{{person?.countryOfResidence}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-person>
    <tr>
      <td colspan="5">
        <div class="row">
          <div class="col-md-4">
            <div><b>Title</b></div>
            <div>{{person?.title}}</div>
          </div>
          <div class="col-md-4">
            <div><b>PSC Type</b></div>
            <div>{{person?.personType}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Appointment Date</b></div>
            <div>{{person?.notifiedOn | date:'dd-MMM-yyyy'}}</div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-4">
            <div><b>Nature Of Control</b></div>
            <div>{{person?.natureOfControl}}</div>
          </div>
          <div class="col-md-4">
            <div><b>PSC Address</b></div>
            <div>{{person?.address?.simpleValue}}</div>
          </div>
          <div class="col-md-4">
            <div><b>Terminated Date</b></div>
            <div>{{person?.ceasedOn | date:'dd-MMM-yyyy'}}</div>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
