<section id="personal-search" class="search">
  <div class="container">
    <div class="logo-div d-flex justify-content-center">
      <img class="logo" src="assets/Images/new/HUT-icon.v3-personal-RO.png" />
    </div>
    <form role="form" [formGroup]="personalForm">
      <div class="form-group row form-top-margin">
        <div class="col-md-9 form-data-background form-borderdesign  shadow-lg p-3" formGroupName="profile">
          <div class="row padding-form-design">
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Reference:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                      <p class="text-danger" *ngIf="personalForm.get('profile.reference').errors?.required">
                        <strong><small>{{messagingService.ReferenceRequiredMessage}}</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Name:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="name" autocomplete="off" />
                      <p class="text-danger" *ngIf="personalForm.get('profile.name').errors?.required">
                        <strong><small>{{messagingService.NameRequiredMessage}}</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Post Code:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="postcode" autocomplete="off" (input)="onPostCodeChange()" />
                      <p class="text-danger" *ngIf="personalForm.get('profile.postcode').errors?.pattern">
                        <strong><small>{{messagingService.PostCodeNotValidMessage}}</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Mobile:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="mobile" autocomplete="off" (input)="onMobileChange()" />
                      <p class="text-danger" *ngIf="personalForm.get('profile.mobile').errors?.pattern">
                        <strong><small>Please enter UK numbers only</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Email:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="email" placeholder="" class="form-control" formControlName="email" autocomplete="off" (input)="onEmailChange()" />
                      <p class="text-danger" *ngIf="personalForm.get('profile.email').errors?.pattern">
                        <strong><small>{{messagingService.EmailNotValidMessage}}</small></strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>DOB:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="date" placeholder="" class="form-control datepicker" formControlName="dob" autocomplete="off" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>1st Line Address:</b>
                  </div>
                  <div class="col-md-8 pt-3">
                    <div class="form-group">
                      <input type="text" placeholder="" class="form-control" formControlName="address" autocomplete="off" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 z-index-formdata">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                    <b>Associated Entities:</b>
                  </div>
                  <div class="col-md-8 pt-3" formArrayName="associations">
                    <div class="row">
                      <div class="col-md-6" *ngFor="let skill of associationEntities.controls; let i=index; let last=last">
                        <div [formGroupName]="i">
                          <div class="form-group">
                            <input type="text" placeholder="" class="form-control" formControlName="name" autocomplete="off" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 padding-form-design pb-2">
              <div class="row">
                <div class="input-group">
                  <div class="col-md-12 offset-md-3">
                    <div class="form-group">
                      <button type="submit" class="btn colorRed mx-2"
                              (click)="personalSearch()"
                              [disabled]="criminalityResultModel?.isLoading || addressResultModel?.isLoading ||
                              financialResultModel?.isLoading || amlResultModel?.isLoading || emailageResultModel?.isLoading ||
                              counterFraudResultModel?.isLoading || mobileResultModel?.isLoading || associatedResultModel?.isLoading ||
                              ccjResultModel?.isLoading || companiesHouseResultModel?.isLoading || emailValidationResultModel?.isLoading ||
                              insolvencyResultModel?.isLoading || opensourceResult?.isLoading ||
                              piplResultModel?.isLoading || socialMediaResultModel?.isLoading || sportsResultModel?.isLoading ||
                              t2aResultModel?.isLoading || prospectResultModel?.isProspectHitLoading || landRegistryResultModel?.isLoading">
                        Search <i class="fa fa-search" aria-hidden="true"></i>
                      </button>

                      <button type="button" class="btn colorRed btn-pdf mx-2"
                              *ngIf="saveAsPDFButtonVisibility"
                              (click)="generatePDF()"
                              [disabled]="criminalityResultModel?.isLoading || addressResultModel?.isLoading ||
                              financialResultModel?.isLoading || amlResultModel?.isLoading || emailageResultModel?.isLoading ||
                              counterFraudResultModel?.isLoading || mobileResultModel?.isLoading || associatedResultModel?.isLoading ||
                              ccjResultModel?.isLoading || companiesHouseResultModel?.isLoading || emailValidationResultModel?.isLoading ||
                              insolvencyResultModel?.isLoading || opensourceResult?.isLoading ||
                              piplResultModel?.isLoading || socialMediaResultModel?.isLoading || sportsResultModel?.isLoading ||
                              t2aResultModel?.isLoading || prospectResultModel?.isProspectHitLoading || landRegistryResultModel?.isLoading">
                        Save as PDF
                      </button>

                      <button class="btn colorRed reset-btn mx-2"
                              *ngIf="clearSearchButtonVisibility"
                              (click)="personalClearSearch()"
                              [disabled]="criminalityResultModel?.isLoading || addressResultModel?.isLoading ||
                              financialResultModel?.isLoading || amlResultModel?.isLoading || emailageResultModel?.isLoading ||
                              counterFraudResultModel?.isLoading || mobileResultModel?.isLoading || associatedResultModel?.isLoading ||
                              ccjResultModel?.isLoading || companiesHouseResultModel?.isLoading || emailValidationResultModel?.isLoading ||
                              insolvencyResultModel?.isLoading || opensourceResult?.isLoading ||
                              piplResultModel?.isLoading || socialMediaResultModel?.isLoading || sportsResultModel?.isLoading ||
                              t2aResultModel?.isLoading || prospectResultModel?.isProspectHitLoading || landRegistryResultModel?.isLoading">
                        Clear Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 toggle-div form-borderdesign result-form-background shadow-lg p-3 #F7F7F7" formGroupName="access">
          <ul class="ul-toggle">
            <li class="result-animation"
                [ngClass]="personalForm.get('profile.name').value && personalForm.get('profile.address').value &&
                personalForm.get('profile.postcode').value ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" formControlName="landregistry">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Identity.svg" class="icon-size" />
              <span>Land Registry</span>
            </li>
            <li class="result-animation" [ngClass]="personalForm.get('profile.name').value && personalForm.get('profile.postcode').value ? 'result-get-list-font-design': 'checkmark-default-design'">
              <label class="switch">
                <input type="checkbox" formControlName="financial">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Financial.svg" class="icon-size" />
              <span>Financial</span>
            </li>
            <li class="result-animation checkmark-default-design">
              <label class="switch">
                <input type="checkbox" formControlName="quick">
                <span class="slider round"></span>
              </label>
              <img src="assets/Images/icons/Financial.svg" class="icon-size" />
              <span><span>Quick Search</span><span class="exclude-criminality">(excludes criminality)</span></span>
            </li>
          </ul>
          <div *ngIf="disableExit()">
            <div class="search-inprogress">Search In Progress</div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div>
    <div class="container" *ngIf="showResultTab">

      <div class="row mb-3" id="new-dashboard">
        <div class="col-md-3 card">
          <app-counterfraud-new-result-personal [counterFraud]="counterFraudResultModel" [financial]="financialResultModel" [mobile]="mobileResultModel"></app-counterfraud-new-result-personal>
        </div>
        <div class="col-md-3 card">
          <app-financial-new-result-personal [financial]="financialResultModel"></app-financial-new-result-personal>
        </div>
        <div class="col-md-3 card">
          <app-email-new-result-personal [emailage]="emailageResultModel" [financial]="financialResultModel"></app-email-new-result-personal>
        </div>
        <div class="col-md-3 card">
          <app-mobile-new-result-personal [mobile]="mobileResultModel" [financial]="financialResultModel" [requestData]="requestDataModel"></app-mobile-new-result-personal>
        </div>
      </div>
    </div>

    <div class="container" *ngIf="showResultTab">
      <div class="mb-5">
        <div class="background-white result-display-padding form-borderdesign result-view-align" style="border: 1px solid rgba(0,0,0,.125);">
          <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="address-search-tab" data-toggle="tab" href="#addressSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Address</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="landregistry-search-tab" data-toggle="tab" href="#landRegistrySearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Land Registry</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="aml-search-tab" data-toggle="tab" href="#amlSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>AML</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="association-search-tab" data-toggle="tab" href="#associationSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Association</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="ccj-search-tab" data-toggle="tab" href="#ccjSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>CCJ</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="companieshouse-search-tab" data-toggle="tab" href="#companieshouseSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Companies House</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="criminality-search-tab" data-toggle="tab" href="#criminalitySearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Crime &amp; Legal</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="email-search-tab" data-toggle="tab" href="#emailSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Email</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="insolvency-search-tab" data-toggle="tab" href="#insolvencySearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Insolvency</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="mobile-search-tab" data-toggle="tab" href="#mobileSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Mobile</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="opensource-search-tab" data-toggle="tab" href="#opensourceSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Opensource</b></a>
            </li>
            <li class="nav-item" *ngIf="prospectResultModel.isProspectHitAccess">
              <a class="nav-link" id="prospect-search-tab" data-toggle="tab" href="#prospectSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Prospect</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="socialmedia-search-tab" data-toggle="tab" href="#socialmediaSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Social Media</b></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="sports-search-tab" data-toggle="tab" href="#sportsSearch" role="tab"
                 aria-controls="profile" aria-selected="false"><b>Sports</b></a>
            </li>
          </ul>

          <div class="tab-content" id="searchResultTabContent">
            <br />
            <!---------------------- Category Search table for Main results --------------->
            <!-- Address Results -->
            <div class="tab-pane fade show tab-height active" id="addressSearch" role="tabpanel" aria-labelledby="address-search-tab">
              <app-address-result-personal [address]="addressResultModel"></app-address-result-personal>
              <app-t2a-result-personal [t2a]="t2aResultModel"></app-t2a-result-personal>
            </div>

            <!-- AML Result -->
            <div class="tab-pane fade show tab-height" id="amlSearch" role="tabpanel" aria-labelledby="aml-search-tab">
              <app-aml-result-personal [aml]="amlResultModel"></app-aml-result-personal>
            </div>

            <!-- Association Result -->
            <div class="tab-pane fade show tab-height" id="associationSearch" role="tabpanel" aria-labelledby="association-search-tab">
              <app-association-result-personal [associated]="associatedResultModel"></app-association-result-personal>
            </div>

            <!-- Email Result -->
            <div class="tab-pane fade show tab-height" id="emailSearch" role="tabpanel" aria-labelledby="email-search-tab">
              <app-emailvalidation-result-email [emailValidation]="emailValidationResultModel"></app-emailvalidation-result-email>
              <br />
              <app-emailage-result-email [emailage]="emailageResultModel"></app-emailage-result-email>
            </div>

            <!-- Financial Result -->
            <div class="tab-pane fade show tab-height" id="ccjSearch" role="tabpanel" aria-labelledby="ccj-search-tab">
              <app-ccj-result-personal [ccj]="ccjResultModel"></app-ccj-result-personal>
            </div>

            <!-- Comapnies House Result -->
            <div class="tab-pane fade show tab-height" id="companieshouseSearch" role="tabpanel" aria-labelledby="companieshouse-search-tab">
              <app-companies-house-result-personal [companiesHouse]="companiesHouseResultModel"></app-companies-house-result-personal>
            </div>

            <!-- Criminality Result -->
            <div class="tab-pane fade show tab-height" id="criminalitySearch" role="tabpanel" aria-labelledby="criminality-search-tab">
              <app-criminality-result-personal [criminality]="criminalityResultModel" (criminalityEventEmitter)="criminalityEventEmitter($event)"></app-criminality-result-personal>
            </div>

            <!-- Insolvency Result -->
            <div class="tab-pane fade show tab-height" id="insolvencySearch" role="tabpanel" aria-labelledby="insolvency-search-tab">
              <app-insolvency-result-personal [insolvency]="insolvencyResultModel"></app-insolvency-result-personal>
            </div>

            <!-- Mobile Result -->
            <div class="tab-pane fade show tab-height" id="mobileSearch" role="tabpanel" aria-labelledby="mobile-search-tab">
              <app-mobile-result-mobile [mobile]="mobileResultModel"></app-mobile-result-mobile>
            </div>

            <!-- Opensource Result -->
            <div class="tab-pane fade show tab-height" id="opensourceSearch" role="tabpanel" aria-labelledby="opensource-search-tab">
              <app-opensource-result-mobile [opensource]="opensourceResult"></app-opensource-result-mobile>
            </div>

            <!-- Prospect Result -->
            <div class="tab-pane fade show tab-height" id="prospectSearch" role="tabpanel" aria-labelledby="prospect-search-tab">
              <app-prospect-result-personal [prospect]="prospectResultModel" (prospectProfileRequestEventEmitter)="prospectProfileRequest()"></app-prospect-result-personal>
            </div>

            <!-- Social Media Result -->
            <div class="tab-pane fade show tab-height" id="socialmediaSearch" role="tabpanel" aria-labelledby="socialmedia-search-tab">
              <app-pipl-result [pipl]="piplResultModel"></app-pipl-result>
              <br />
              <app-socialmedia-result-personal [socialMedia]="socialMediaResultModel"></app-socialmedia-result-personal>
            </div>

            <!-- Sports Result -->
            <div class="tab-pane fade show tab-height" id="sportsSearch" role="tabpanel" aria-labelledby="sports-search-tab">
              <app-sports-result-personal [sports]="sportsResultModel"></app-sports-result-personal>
            </div>

            <!-- Land Registry -->
            <div class="tab-pane fade show tab-height" id="landRegistrySearch" role="tabpanel" aria-labelledby="landregistry-search-tab">
              <app-landregistry-result-personal [landRegistry]="landRegistryResultModel"></app-landregistry-result-personal>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-1"></div>
    </div>
  </div>
</section>
<p-confirmDialog key="ProspectProfileRequest" [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<div style="position: absolute; top: 0; right: 120%;">
  <kendo-pdf-export #pdf>
    <app-export-pdf-person [requestData]="requestDataModel"
                           [financial]="financialResultModel"
                           [emailage]="emailageResultModel"
                           [counterFraud]="counterFraudResultModel"
                           [mobile]="mobileResultModel"
                           [address]="addressResultModel"
                           [aml]="amlResultModel"
                           [associated]="associatedResultModel"
                           [ccj]="ccjResultModel"
                           [companiesHouse]="companiesHouseResultModel"
                           [criminality]="criminalityResultModel"
                           [emailValidation]="emailValidationResultModel"
                           [insolvency]="insolvencyResultModel"
                           [opensource]="opensourceResult"
                           [prospect]="prospectResultModel"
                           [pipl]="piplResultModel"
                           [socialMedia]="socialMediaResultModel"
                           [sports]="sportsResultModel"
                           [t2a]="t2aResultModel"
                           [landRegistry]="landRegistryResultModel"></app-export-pdf-person>
  </kendo-pdf-export>
</div>
