import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Audit } from '../../shared/models/audit';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor(public datepipe: DatePipe) { }

  public exportAsExcelFile(json: Audit[], excelFileName: string): void {

    let mappedJson = json.map(item => {
      return {
        "Reference": item.reference,
        "Search Date": this.datepipe.transform(item.searchDate, 'dd-MM-YYYY HH:mm:ss', 'en-US'),
        "Username": item.userName,
        "Search Name": item.searchName,
        "Type": item.searchType,
        "VRN": item.vrn,
        "Vehicle Check": item.vrn ? item.vechileCheck : "",
        "Is Valid Profile": item.searchType == 'HeadsupAPI' && item.apimi ? item.apimi.isValidProfile : ''
      }
    });

    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedJson);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
