import { SocialMediaStatus } from "../../../../home/models/SocialMediaStatus";
import { Items } from "./Items";

export class SocialMediaResultModel {
  public socialMediaCategory: string[] = ["facebook", "instagram", "linkedin", "twitter", "youtube", "tiktok"];
  public socialMediaStatus: SocialMediaStatus[] = [];
  public collapseSocialMediaClass: string;
  public collapseSocialMediaStatus: string;
  public collapseSocialMediaIcon: string;
  public socialMediaResults: Items[] = [];
  public finalSocialMediaResults: Items[] = [];
  public compareSocialMediaCategory: string[] = [];
  public filtersocialMediaStatus: SocialMediaStatus;
  public socialMediaSuccessCount: number = 0;
  public SocialMediaResultCount: number;
  public noSearchSelect: boolean;
  public isLoading: boolean;
}
